import React, { useState } from 'react';
import { Box, IconButton, CircularProgress, Button } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import TransformIcon from '@mui/icons-material/Transform';
import { OutlinedInput } from '@mui/material';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import StackedBarChartOutlinedIcon from '@mui/icons-material/StackedBarChartOutlined';
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import SelectColumnComponent from './ui/SelectColumnComponent';
import SelectDecimal from './ui/SelectDecimal';
import TableViewIcon from '@mui/icons-material/TableView';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { GithubPicker } from 'react-color';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

const ChartControlComponent = props => {
  const {
    // options,
    compareChart,
    control,
    decimalPlace,
    setDecimalPlace,
    rotateChart,
    cDisplayType,
    columnIndex,
    getCompareColumns,
    // showTable,
    compareState,
    showTable,
    toggleTable,
    setChartGroupStyle,
    // tableView,
    // setTableView,
    numberPercentView,
    changeTableView,
    transposeStatus,
    setTransposeStatus,
    // transposeTableData,
    setBackgroundColor,
    backgroundColor,
    chatText,
    setChatText,
    genAIReport,
    aiData,
    showAiForm,
    setShowAiForm,
  } = props;

  const [showColorPallete, setShowColorPallete] = useState(false);

  const tips = {
    isStack: { text: 'Stacked chart! Click to group' },
    isGroup: { text: 'Grouped chart! Click to stack' },
    rotateChart: { text: 'Click to make the chart clockwise' },
    hideTable: { text: 'Table hidden. Click to show table' },
    showTable: { text: 'Table shown. Click to hide table' },
    showNumbers: { text: 'Show or hide numbers on table' },
    showNumbers_Percent: { text: 'Show both numbers and percentage (%)' },
    showPercent: { text: 'Show/hide percentage (%) on table' },
    rotateTable: { text: 'Click to rotate/transpose table' },
    showColorPallete: { text: 'Click to show/hide color pallete to change background color' },
    showTextbox: { text: 'Show/hide text box you can use to generate report based on the result using ChatGPT AI ' },
    chatText: { text: `1. This textbox allows you to generate report/summary for the result using ChatGPT AI
    \n\nYou can Type a new text or modify the text before sending\n\n
    Ensure to include the nature/type of data you are analyzing. This could include the source of the data or what it is about.
    Example: 'Create a report based on the following data of cancer patient comparing their ages.
    \n\nYou do not need to include the data, as it will be automatically included in the request to ChatGPT.` }
  }

  const colors = ['#FFF', '#FFFEEA', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB'];
 
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  function RotateTableIcon () {
    return (
      <React.Fragment>
      <HtmlTooltip
      title={
        <React.Fragment>
          { tips.rotateTable.text }
        </React.Fragment>
      }
    >
    <TransformIcon fontSize='small' />
    </HtmlTooltip>
    </React.Fragment>
  )}


  function ShowNumberIcon () {
    return (
      <React.Fragment>
      <HtmlTooltip
      title={
        <React.Fragment>
          { tips.showNumbers.text }
        </React.Fragment>
      }
    >
    <PinOutlinedIcon fontSize='smail' color={numberPercentView.number? "primary" : ""} />
    </HtmlTooltip>
    </React.Fragment>
  )}

  function ShowPercentIcon () {
    return (
      <React.Fragment>
      <HtmlTooltip
      title={
        <React.Fragment>
          { tips.showPercent.text }
        </React.Fragment>
      }
    >
    <PercentOutlinedIcon fontSize='small' color={numberPercentView.percent? "primary" : ""} />
    </HtmlTooltip>
    </React.Fragment>
  )}

  function IsGroupIcon () {
    return (
      <React.Fragment>
      <HtmlTooltip
          title={
            <React.Fragment>
              { tips.isGroup.text }
            </React.Fragment>
          }
        >
        <SignalCellularAltIcon fontSize='small' />
    </HtmlTooltip>
    </React.Fragment>
  )}

  function IsStackIcon () {
    return (
      <React.Fragment>
      <HtmlTooltip
          title={
            <React.Fragment>
              { tips.isStack.text }
            </React.Fragment>
          }
        >
        <StackedBarChartOutlinedIcon fontSize='small'/>
    </HtmlTooltip>
    </React.Fragment>
  )}


  function ColorPaletteIcon () {
    return (
      <React.Fragment>
      <HtmlTooltip
          title={
            <React.Fragment>
              { tips.showColorPallete.text }
            </React.Fragment>
          }
        >
        <FormatColorFillOutlinedIcon fontSize='small' /> 
    </HtmlTooltip>
    </React.Fragment>
  )}

  function selectColor(color) {
    // alert(color.hex)
    setBackgroundColor(color.hex);
  }

  return (
    <div>
    <Grid container spacing={1}>
      {/* ___ {backgroundColor} */}
    <Grid item xs={7}>
        <div>
      <Box
        sx={{
          '& > :not(style)': {
            m: 2,
          },
        }}
      >
        {/* <NumbersIcon color="secondary" /> */}
        <IconButton onClick={rotateChart}><RotateRightOutlinedIcon fontSize='small' /></IconButton >
        { compareState < 2 && cDisplayType === 'isGroup' && <IconButton onClick={()=>setChartGroupStyle('isStack')} ><IsGroupIcon /></IconButton> }
        { compareState < 2 && cDisplayType === 'isStack' && <IconButton onClick={()=>setChartGroupStyle('isGroup')} ><IsStackIcon /></IconButton> }

        { compareState === 1 && showTable && <IconButton onClick={()=>setTransposeStatus(!transposeStatus)}><RotateTableIcon /></IconButton> }

        { compareState === 1 && showTable && <IconButton onClick={()=>changeTableView('number')}><ShowNumberIcon /></IconButton>}

        {/* <SliderPicker colors={colors} onChange={selectColor} /> */}

        { compareState === 1 && showTable && <IconButton onClick={()=>changeTableView('percent')}><ShowPercentIcon /></IconButton> }

        { <IconButton onClick={()=>setShowAiForm(!showAiForm)}>
          <HtmlTooltip
            title={
              <React.Fragment>
                { tips.showTextbox.text }
              </React.Fragment>
            }
          >
          <PsychologyIcon />
          </HtmlTooltip>
          </IconButton> }

        { compareState > 0 &&
        <IconButton>
        <HtmlTooltip
          title={
            <React.Fragment>
              { showTable ? tips.showTable.text : tips.hideTable.text }
            </React.Fragment>
          }
        >
        <TableViewIcon fontSize='small' onClick={toggleTable} />
      </HtmlTooltip>
      </IconButton>
      }

      <IconButton onClick={()=>setShowColorPallete(!showColorPallete)}><ColorPaletteIcon /></IconButton>
      {/* <HexColorPicker /> */}
      </Box>
      </div>
    </Grid>

    <Grid item xs={2}>
      { control && control.compareState === 2 && <SelectDecimal decimalPlace={decimalPlace} width={2} setDecimalPlace={setDecimalPlace} /> }
    </Grid>
    <Grid item xs={3}>
      { <SelectColumnComponent
        options={getCompareColumns(columnIndex)}
        onChange={compareChart}
        option={control.compareWith}
      />
      }
    </Grid>
  </Grid>

  <Grid container>
  <Grid item xs={3} md={4}>{ showColorPallete && <GithubPicker colors={colors} onChange={selectColor} /> }</Grid>
      {showAiForm && <Grid item xs={7} md={6}>
        <div sx={{p: 2}} style={{padding: 2, margin: 2}}>
          <span>Type commands to generate report with ChatGPT AI
            based on this data
          </span>
        </div>
        
        <OutlinedInput
          id="chatText"
          value={chatText}
          size="small"
          style={{width: '100%'}}
          rows={2}
          multiline
          backgroundColor="warning"
          onChange={(e)=>setChatText(e.target.value)}
        />
      </Grid>}
      {showAiForm && <Grid item xs={2}>
        <Button variant="outlined" size='small' sx={{mt: 7, mx: 2}} onClick={genAIReport}>
        { aiData.loading && <CircularProgress color='inherit' size={14} />}
          Ask ChatGPT
        </Button>
      </Grid>}
    </Grid>

  </div>
  )
}

export default ChartControlComponent;