import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PaidIcon from '@mui/icons-material/Paid';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import AdbIcon from '@mui/icons-material/Adb';
import SlackIcon from '../media/iconsduck/slack.svg';
import DiscordIcon from '../media/iconsduck/discord.svg';

// const card = (
//   <React.Fragment>
//     <CardContent>
//       <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
//         Word of the Day
//       </Typography>
//       <Typography variant="h5" component="div">
//         be{bull}nev{bull}o{bull}lent
//       </Typography>
//       <Typography sx={{ mb: 1.5 }} color="text.secondary">
//         adjective
//       </Typography>
//       <Typography variant="body2">
//         well meaning and kindly.
//         <br />
//         {'"a benevolent smile"'}
//       </Typography>
//     </CardContent>
//     <CardActions>
//       <Button size="small">Learn More</Button>
//     </CardActions>
//   </React.Fragment>
// );

const getAILimit = (ai_report_limit = 5, monthly_request_limit) => {
  return new Intl.NumberFormat('en').format(Number(ai_report_limit) * Number(monthly_request_limit));
} 

export default function PricingCard(props) {
  const { name, price, row_limit, column_limit, monthly_request_limit, file_size, ai_report_limit } = props;
  return (
    <Box sx={{ minWidth: 75}}>
      <Card variant="outlined" sx={{backgroundColor: Number(price) > 0 ? '#EFF4F9': '#FFFFFF'}}>
        <CardContent>
          <Typography variant="h6" component="div" sx={{fontWeight: 800, color: '#5A4BA1'}}>
            {name}
          </Typography>
          {/* <Typography spacing={{ xs: 2, md: 3 }} sx={{ mb: 1.5 }} color="text.secondary">
            adjective
          </Typography> */}
          {/* <Typography variant="body2">
            well meaning and kindly.
            <br />
            {'"a benevolent smile"'}
          </Typography> */}
          {/* <Typography variant="h6" sx={{ mt: 1, fontWeight: 600 }}>
            <PaidIcon /> <span style={{ marginTop: '-12px'}}>${price}/mo</span>
          </Typography> */}
          <Box sx={{my: 2}}>
            <PaidIcon sx={{float: 'left', mt: 0.5}} />
            <Typography variant="h6" sx={{ mt: 0, fontWeight: 600, ml: 4 }}>
              ${price}/mo
            </Typography>
          </Box>

          <Box sx={{my: 2}}>
            <PeopleAltIcon sx={{float: 'left', mt: 0.5}} />
            <Typography variant="body1" sx={{ mt: 0, ml: 4 }}>
              Unlimited users
            </Typography>
          </Box>

          <Box sx={{my: 2}}>
            <IntegrationInstructionsIcon sx={{float: 'left', mt: 0.5}} />
            <Typography variant="body1" sx={{ mt: 0, ml: 4 }}>
              Unlimited platforms including Slack <img src={SlackIcon} height={16} />,
              Discord<img src={DiscordIcon} height={26} />...
            </Typography>
          </Box>

          <Box sx={{my: 2}}>
            <AspectRatioIcon sx={{float: 'left', mt: 0.5}} />
            <Typography variant="body1" sx={{ mt: 0, ml: 4 }}>
            {row_limit && new Intl.NumberFormat('en').format(row_limit) } x 
            &nbsp;{column_limit && new Intl.NumberFormat('en').format(column_limit) } data dimension 
            </Typography>
          </Box>

          <Box sx={{my: 2}}>
            <CalendarViewMonthIcon sx={{float: 'left', mt: 0.5}} />
            <Typography variant="body1" sx={{ mt: 0, ml: 4 }}>
              {monthly_request_limit && new Intl.NumberFormat('en').format(monthly_request_limit) } monthly request
            </Typography>
          </Box>

          <Box sx={{my: 2}}>
            <AdbIcon sx={{float: 'left', mt: 0.3}} />
            <Typography variant="body1" sx={{ mt: 0, ml: 4 }}>
            {getAILimit(ai_report_limit, monthly_request_limit) } monthly AI request
            using ChatGPT
            </Typography>
          </Box>

        </CardContent>
        <CardActions>
          <Button size="small" href={`https://app.evoked.io?p=${name.toLowerCase()}`}>Get started</Button>
        </CardActions>
      </Card>
    </Box>
  );
}