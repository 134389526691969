import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getTableValue } from '../../helpers/helper';

export default function ChartTable(props) {
  const { numberPercentView, tableInfo } = props;
  const { headers, values, rowTopics } = tableInfo;

  useEffect(()=> {
    // getHeader();
  },[]);

  // const intialCap = (val) => {
  //   return val[0].toLocaleUpperCase() + val.subString(1, val.length - 1); 
  // }

  const getView = () => {
    if (numberPercentView.number && numberPercentView.percent) {
      return 'both';
    }

    if (numberPercentView.percent) {
      return 'percent';
    }

    return 'number';
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell><b>Categories</b></TableCell>
            { headers && headers.length && headers.map((header, index) => (
              <TableCell align="right" key={index}><b>{header}</b> &nbsp;</TableCell>
            )) }
          </TableRow>
        </TableHead>

        <TableBody>
          { values && values.length > 0 && values.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope='row'>
                <b>{rowTopics[index]}</b>
              </TableCell>
              { row && row.length > 0 && row.map((val, valIndex)=> (
                <TableCell align="right" key={valIndex}>{getTableValue(val, tableInfo.totalArray[valIndex], getView())}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
