import React from 'react';
import { Box, Container, Button, Card, CardContent, Typography, Paper, Grid, CardMedia, CardActionArea } from '@mui/material';
import AppBarComponent from './components/ui/AppBarComponent';
import Footer from './components/ui/Footer';
// import SlackImg1 from './components/media/logos/slack.svg.png';
import slack01 from './components/media/logos/slack_01.jpg';
import slack02 from './components/media/logos/slack_02.jpg';
import DiscordImg1 from './components/media/logos/discord1.png';
import integrationImg from './components/media/storyset/svgs/operating-system.svg';
import slackIcon from './components/media/flats/icons/slack.svg';
import discordIcon from './components/media/flats/icons/discord.svg';
import integrations_workspaces from './components/media/illustrations/integrations_workspace.svg'

let slackInstallButton = 'https://slack.com/oauth/v2/authorize?client_id=5576615948067.5576899212882&scope=app_mentions:read,im:history,links:read,chat:write,files:read,files:write,im:read,mpim:history,channels:join,channels:history,groups:history,chat:write.customize&user_scope='
if (process.env.REACT_APP_NODE_ENV !== 'development') {
  slackInstallButton = 'https://slack.com/oauth/v2/authorize?client_id=5576615948067.5576899212882&scope=app_mentions:read,channels:join,chat:write,files:read,im:history,links:read,channels:read,groups:read,im:read,mpim:history,team:read,users:read,users:read.email&user_scope=identify,users:read,users:read.email&state=install';
}
const platforms = [
  {
    name: 'Slack',
    bgColor: '#fff7fd', // Slack color #611F69
    logo: slack01,
    installButton: slackInstallButton,
    link: 'https://slack.com',
    about: `Slack is a messaging app for business that connects people to the information they need.`
  },
  {
    name: 'Discord',
    bgColor: '#f2f3fe', // Discord color #404EED
    logo: DiscordImg1,
    installButton: 'https://discord.com/oauth2/authorize?client_id=1121772755659014298',
    link: 'https://discord.com',
    about: `Discord is a voice, video and text communication service used by over a hundred million people to hang out and talk with their friends and communities.`
  },
]

const SlackButton = () => <Box><a href="https://slack.com/oauth/v2/authorize?client_id=5576615948067.5576899212882&scope=app_mentions:read,im:history,links:read,chat:write,files:read,files:write,im:read,mpim:history,channels:join,channels:history,groups:history,chat:write.customize&user_scope="><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a></Box>

const TopSection = () => (
  <Box>
    <Box sx={{mt: 8}}>
    <Grid container spacing={2} sx={{ height: 600, backgroundColor: '' }}>
    <Grid item xs={1}></Grid>
      <Grid item xs={5}>
        <Box sx={{ mt: 10 }}>
        <Typography sx={{ fontWeight: 700, mt: 3, color: '#000' }} variant='h3' color="text.secondary" gutterBottom>
          Connect to your favorite workspaces
        </Typography>
        </Box>
        <Box sx={{my: 3}}>
          <img src={slackIcon} height={50} width={50} style={{marginRight: 15}} />
          <img src={discordIcon} height={50} width={50} />
        </Box>
        <Typography sx={{ mb: 1.5, mt: 0, fontSize: 16, color: '#000' }} color="text.secondary">
          More platforms coming soon...
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Container>
          {/* <img src={integrationImg} /> */}
          <img src={integrations_workspaces} />
        </Container>
      </Grid>
    </Grid>
    </Box>
</Box>
)

const TextContainer = ({data}) =>(
  <Container sx={{my: 4}}>
    <Box>
      <Typography variant='h5' sx={{ fontWeight: 800 }}>{data.name}</Typography>
    </Box>
    <Box sx={{lineHeight: 1.5, mt: 2 }}>
      <Typography>{data.about}</Typography>
    </Box>
  </Container>
);

const ImageBox = ({data}) =>(
  <Box>
  <Card>
    <CardActionArea href={data.link}>
    <CardMedia
      component="img"
      alt={data.name}
      height="280"
      image={data.logo}
    />
    </CardActionArea>
  </Card>
</Box>
)

const PlatformsSection = (props) =>{
  const {index} = props;
  const p = platforms[index];
  return (
    <Box sx={{ px: 2, py: 8, backgroundColor: p.bgColor, height: 500 }}>
        <Container sx={{ pt: 4, mt: 6 }}>

          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
            { index%2 == 1 && <TextContainer data={p} /> }
              { index%2 == 0 && <ImageBox data={p} /> }

              {index%2 == 1 && <Button variant='outlined' href={p.installButton}> Add Bot to your {p.name} </Button>}
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              { index%2 == 0 && <TextContainer data={p} />}
              { index%2 == 1 && <ImageBox data={p} />}
              <Box sx={{px: 4}}>
              {index%2 == 0 && p.name !== 'Slack' && <Button variant='outlined' href={p.installButton}>Add Bot to your {p.name} </Button>}
              { p.name === 'Slack' &&  <SlackButton />}
              </Box>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Container>
  </Box>
  )
  };
const Integrations = () => {
  return (
    <Box sx={{ minWidth: 275 }}>
      <AppBarComponent />
      <TopSection />
      {
        platforms.map((p, i)=>(<PlatformsSection index={i} />))
      }
      <Footer />
    </Box>
  );
}

export default Integrations;
