import * as React from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import ResultFace from './components/ResultFace';
import { views } from './constants/general';
import axios from 'axios';
import LoginDialog from './components/auth/login-dialog';
import AppBarComponent from './components/ui/AppBarComponent';
import Footer from './components/ui/Footer';
const resultApi = process.env.REACT_APP_RESULT_API_ENDPOINT;

function Result() {
  const params = useParams()
  const [searchParams] = useSearchParams();
  const accessType = searchParams.get('at');
  const { resultReference } = params;
  const [resultSession, setResultSession ] = React.useState({
    showResult: 0,
    resultReference: '',
    accessCode: '',
    loading: 1,
    message: '',
    view: '',
    accessType: '',
  });
  const [result, setResult] = React.useState({})
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const { LOGIN, RESULT } = views;

  const getResult = async (payload) => {
    const { accessCode } = payload;

    setResultSession({ ...resultSession, loading: true })
    setLoading(true);

    try {
      const { data } = await axios.post(`${resultApi}/result/${params.resultReference}`, payload);
      if (data.result) {
        setResult(data)
        setResultSession({ ...resultSession, view: RESULT, showResult: 1, loading: false, accessCode })
        setLoading(false);
        const rs = { resultReference: params.resultReference, date: Date.now(), accessCode }
        localStorage.setItem("result", JSON.stringify(rs));
        return
      };
    } catch (error) {
      const { response: { data } } = error;
      const message = data ? data.message : "Error fetching data. Please try again later."
      setLoading(false);
      setError(true);
      setResultSession({ ...resultSession, message })
    }
  }

  React.useEffect(() => {
    setResultSession({ ...resultSession, resultReference, accessType });
    let res = localStorage.getItem("result");
    if (!res) {
      setResultSession({ ...resultSession, resultReference, view: LOGIN })
      return
    }
    res = JSON.parse(res);
    if (res.resultReference !== resultReference || (res.date + 159600) < Date.now()) {
      setResultSession({ ...resultSession, resultReference, view: LOGIN })
      return;
    }

    const payload = {
      accessCode: res.accessCode,
      reference: res.resultReference,
      accessType,
    }

    getResult(payload)
  }, []);

  const loginProps = {
    resultSession,
    setResultSession,
    setResult,
    getResult,
    loading,
    error,
    setError,
  }

  return (
    <div className="">
      <AppBarComponent hide={true} />
        { resultSession.view === LOGIN && <LoginDialog {...loginProps} open={resultSession.view === LOGIN} /> }
        {/* {resultSession.view === LOGIN && <Login {...loginProps} />} */}
        <div style={{marginTop: '20px'}}>
          {resultSession.view === RESULT && result && result.result && <ResultFace data={result} /> }
        </div>

        {resultSession.view === RESULT && <Footer />}
    </div>
  );
}

export default Result;
