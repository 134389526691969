import React, { useEffect, useState }  from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

const SocialLogin = () => {
  const [platform, setPlatform] = useState('');
  // const [code, setCode] = useState(null);
  // const params = useParams()
  const [searchParams] = useSearchParams();
// const resultApi = process.env.REACT_APP_RESULT_API_ENDPOINT;
const linkedinAccessCodeUrl = `https://www.linkedin.com/oauth/v2/accessToken`;

  function veryfyCode (code) {
    const body = {
      code,
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_LINKEDIN_APP_ID,
      client_secret: "w7fhsUw1l46lPP1S",
      redirect_uri: encodeURIComponent("http://localhost:3000/auth/social"),
    }
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    }
    return axios.post(`${linkedinAccessCodeUrl}`, body, { headers });
  }

  useEffect(() => {
    // console.log('params___> ', params);
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    console.log('searchParams___> ', searchParams, code, state);
    if (state) {
      setPlatform(state);
      // setCode(code);
      veryfyCode(code, platform).then(result => {
        console.log('rezult...: ', result);
      }).catch(err => {
        console.log('erroro: ', err);
      })
    }

  }, []);



  return (
    <div>
      Google Page
    </div>
  )

}

export default SocialLogin;