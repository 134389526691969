import React from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

// import Data_report_amico from '../media/storyset/svgs/Data_report_amico.svg';
import img1 from './components/media/storyset/svgs/Data_report_amico.svg'

// const animationStyles = {
//   bounce: {
//     animation: 'x 2s',
//     animationName: Radium.keyframes(bounce, 'bounce')
//   },
//   fadeIn: {
//     animation: 'x 2s',
//     animationName: Radium.keyframes(fadeIn, 'fadeIn')    
//   },
//   slideInLeft: {
//     animation: 'x 2s',
//     animationName: Radium.keyframes(slideInLeft, 'slideInLeft')    
//   },
//   slideInRight: {
//     animation: 'x 2s',
//     animationName: Radium.keyframes(slideInRight, 'slideInRight')    
//   },
//   tada: {
//     delay: 2,
//     animation: 'x 8s',
//     animationName: Radium.keyframes(tada, 'tada')    
//   }
// };


const Features = props => {
  const { inViewport, forwardedRef, enterCount } = props;
  // console.log('___ ', props);
      // <Card variant="outlined" ref={forwardedRef} className='fnt'>{card(inViewport, enterCount)}</Card>

  const features = [
    { title: 'Analyze data with no data science expertise', text: '', img: img1 },
    { title: 'Instant interative presentation-ready data visualizations', text: '', img: img1 },
    { title: 'Leverage power of Artificial Intelligence', text: '', img: img1 },
    { title: 'Get reuseable analyis', text: '', img: img1 },
    { title: 'Allow team members access', text: '', img: img1 },
    { title: '-----', text: '', img: img1 },
  ];

  const textComponent = props => {
    const { title, text } = props;
    return (
      <Container>
        <h2>{title}</h2>
        <Container>{text}</Container>
      </Container>
    )
  }

//   <div style={enterCount === 1 ? animationStyles.slideInRight : {}}>
//   {inViewport && <img src={Data_report_amico} style={animationStyles.tada} alt="Generate data report" /> }
// </div>


  const imageComp = props => {
    return (
      <Container>
        <img src={props.img} alt={props.title} />
      </Container>
    )
  }

  return (
    <div>
      <Container>
        <Container>
        <Grid container spacing={2}>
          <Grid xs={4}> &nbsp;</Grid>
          <Grid item xs={8}>
            <h1>Everything you need to make your<br />business thrive with data analysis</h1>            
          </Grid>
        </Grid>
        </Container>
        <Container>
          { features.map((f, i) => (
            <Grid container spacing={2} key={i}>
              <Grid item xs={6}>
                { i%2 === 1 ? textComponent(f) : imageComp(f) }
              </Grid>
              <Grid item xs={6}>
              { i%2 === 0 ? textComponent(f) : imageComp(f) }
              </Grid>

            </Grid>
          ))}
        </Container>
      </Container>
    </div>
  )
}

export default Features;