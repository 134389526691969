import React, { useEffect }  from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

const GoogleLogin = () => {
  const params = useParams()
  const [searchParams] = useSearchParams();

  useEffect(() => {
    console.log('params___> ', params);
    console.log('searchParams___> ', searchParams);  
  }, []);

  return (
    <div>
      Google Page
    </div>
  )

}

export default GoogleLogin;