import * as React from 'react';
import {Box, Card, CardContent, Typography, Paper, Grid, Container, CardMedia} from '@mui/material';
import speedImg from '../media/flats/icons/performance.svg';
import noCodeImg from '../media/flats/icons/snap.svg';
import aiImg from '../media/flats/icons/artificial-intellegence.svg';
import freeImg from '../media/flats/icons/no-credit-card.svg';
// import noCodeImg from '../media/flats/icons/no-code.svg';
import multiPlatformIng from '../media/flats/icons/device.svg';
import infographicsImg from '../media/flats/icons/online-analytical.svg';
import NoSkills from '../media/illustrations/no_skills.png'
import NoSkillsSvg from '../media/illustrations/no_skills.svg'
import MultiPlatform from '../media/illustrations/multi_platform.svg'
import AiGbt from '../media/illustrations/ai_gbt.svg';
import infographics from '../media/illustrations/infographics.svg';
import freeInfo from '../media/illustrations/free_info.png'
import fast from '../media/illustrations/fast.svg';
import FeatureCard from './FeatureCard';

const no_skills_note = 'Drop data, wait and view results.\nEveryone in the team can quickly make meaning of their data';
const features = [
  { name: 'No skills required', note: no_skills_note, img: NoSkillsSvg },
  { name: 'Get started free', note: 'No credit card required, though with limited features', img: freeInfo },
  { name: 'Multi-platform support', note: 'Connect with your favorite work tools like Slack, Discord, with more platforms coming soon', img: MultiPlatform },
  { name: 'Interactive Info-graphics', note: 'Visualize analyzed data and perform live comparative analysis', img: infographics },
  { name: 'Chat GPT AI', note: 'Generate report/summary on results of analysis by leveraging the power of Artificial Intelligence', img: AiGbt },
  {name: 'Super fast delivery', note: 'Our robust infrastructure ensures your data is analyzed at blazing speed', img: fast }
]

const AppFeatures = () => {
  
  return (
    <Box>
      <Box sx={{py: 10}}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={8} md={1}></Grid>

          <Grid item xs={4} sm={8} md={10}>
            <Grid container xs={12} sm={12} md={12} sx={{my: 0}}>
              {
                features.map((feature, index) =>(
                  <Grid item xs={12} sm={12} md={4} key={index} sx={{my: 3, pr: 1}}>
                    { feature && (<FeatureCard feature={feature} img={features[index].img} />) }
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default AppFeatures;