import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import {Toolbar, Grid, Icon} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import evokeLogo from '../media/logos/evoke-logo-no-background.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const pages = [
  { name: 'Terms & conditions', slug: 'terms' },
  { name: 'Privacy policy', slug: 'privacy-policy' },
  { name: 'Contact us', slug: 'contact' },
  // { name: 'Request to be featured', slug: 'request-to-be-featured' },
  // { name: 'Terms & conditions', slug: 'terms' },
];

const pageObj = {
  privacy: { id: 'privacy', slug: 'privacy-policy', text: 'Privacy policy' },
  terms: { id: 'terms', slug: 'terms', text: 'Terms & conditions' },
  contact: { id: 'contact', slug: 'contact-us', text: 'Contact us' },
  faqs: { id: 'faqs', slug: 'faqs', text: 'FAQs' },
  pricing: { id: 'pricing', slug: 'pricing', text: 'Pricing' },
  guide: { id: 'guide', slug: 'guide', text: 'How it works' },
  slack: { id: 'slack', text: 'Slack', slug: 'integrations/slack' },
  discord: { id: 'discord', text: 'Discord', slug: 'integrations/discord' },
  facebook: { type: 'social', id: 'facebook', slug: 'https://www.facebook.com/evokeinsight', text: 'Facebook', img: <FacebookIcon /> },
  linkedIn: { type: 'social', id: 'linkedIn', slug: 'https://www.linkedin.com/company/evoke-insight', text: 'LinkedIn', img: <LinkedInIcon /> },
  twitter: { type: 'social', id: 'twitter', slug: 'https://twitter.com/evoke_insight', text: 'X', img: <TwitterIcon /> },
}

export default function Footer() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pageNavigator = (id) => {
    if (pageObj[id].type === 'social') {
      // window.location.replace(pageObj[id].slug);
      window.location.replace(pageObj[id].slug);
      return;
    }

    navigate(`/${pageObj[id].slug}`);
  }

  const FooterItem = ({ data }) => (
    // <MenuItem onClick={()=>navigate(data.type === 'social' ? data.slug : `/${data.slug}`)}>
     <MenuItem onClick={()=>pageNavigator(data.id)}>
      {data.img && <Icon>
        {data.img}
      </Icon>}
    <Typography textAlign="center" sx={{ fontWeight: 400, color: '#00000', mx: 1 }}>{data.text}</Typography>
  </MenuItem>
  )

  return (
    <AppBar position="static" color="appBar" elevation={0}>
      {/* 32DFEC #5A4BA1 */}
      <Box sx={{height: 30}}></Box>
      <Container maxWidth="xl" sx={{ mt: 8, pt: 8 }}>
        <Box>
          <Grid container xs={12} sm={12} md={12} sx={{my: 0}}>
            <Grid item xs={12} sm={12} md={3} sx={{pr: 1}}>
            <img src={evokeLogo} height={30} width={140} />
                <FooterItem data={pageObj.terms} />
                <FooterItem data={pageObj.privacy} />
                <FooterItem data={pageObj.contact} />
            </Grid>

            <Grid item xs={12} sm={12} md={3} sx={{pr: 1}}>
              <Typography variant='body1' sx={{mx: 3, fontWeight: 600}}>Platform</Typography>
              <Box>
                {/* <FooterItem data={pageObj.guide} /> */}
                <FooterItem data={pageObj.faqs} />
                <FooterItem data={pageObj.pricing} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={3} sx={{pr: 1}}>
            <Typography variant='body1' sx={{mx: 3, fontWeight: 600}}>Integrations</Typography>
              <FooterItem data={pageObj.slack} />
              <FooterItem data={pageObj.discord} />
            </Grid>

            <Grid item xs={12} sm={12} md={3} sx={{pr: 1}}>
            <Typography variant='body1' sx={{mx: 3, fontWeight: 600}}>Socials</Typography>
              <FooterItem data={pageObj.linkedIn} />
              <FooterItem data={pageObj.facebook} />
              <FooterItem data={pageObj.twitter} />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={1} sx={{pr: 1}}>
            </Grid> */}
          </Grid>
        </Box>
        <Toolbar disableGutters>
          {/* <Typography
            // variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontSize: 10,
              fontFamily: 'monospace',
              fontWeight: 500,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            &copy; {new Date().getFullYear()} Evoke Insight
          </Typography> */}

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.slug} onClick={()=>navigate(`/${page.slug}`)}>
                  <Typography textAlign="center" sx={{ fontWeight: 600, color: '#00000' }}>{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            &#169; {new Date().getFullYear()}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.slug}
                onClick={()=>navigate(`/${page.slug}`)}
                sx={{ my: 2, color: 'black', display: 'block', fontWeight:500 }}
              >
                {page.name}
              </Button>
            ))}
          </Box> */}
        </Toolbar>

        {/* Footer */}
        <Box sx={{mt:0, px: 4}}>

          {/* <Box>
            <Grid container xs={12} sm={12} md={12} sx={{my: 0}}>
              <Grid item xs={12} sm={12} md={4} sx={{pr: 1}}>
              </Grid>
              <Grid item xs={12} sm={12} md={6} sx={{pr: 1}}>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={page.slug}
                  onClick={()=>navigate(`/${page.slug}`)}
                  sx={{ my: 2, color: 'black', display: 'block', fontWeight:500 }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
              </Grid>
            </Grid>
          </Box> */}


          <Box>
            <Grid container xs={12} sm={12} md={12} sx={{my: 0}}>
              <Grid item xs={12} sm={12} md={5} sx={{pr: 1}}>
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{pr: 1}}>
              <Typography
              // variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                // fontSize: 10,
                // fontFamily: 'monospace',
                // fontWeight: 500,
                // letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              &copy; {new Date().getFullYear()} Evoke Insight
            </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
}
