import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableTitleCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 26,
    fontWeight: 700,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function NumericTables({ summary, title }) {

  const rows = [
    { name: 'Mean', code: 'Mean' },
    { name: 'Median', code: 'Median' },
    { name: 'Min', code: 'Min' },
    { name: 'Max', code: 'Max' },
    { name: "StdDev", code: 'StdDev' },
    { name: 'Q25', code: 'Q25' },
    { name: 'Q50', code: 'Q50' },
    { name: 'Q75', code: 'Q75' },
    ]

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="sticky table">
      <TableHead>
          <TableRow>
            <StyledTableTitleCell align="left">{title}</StyledTableTitleCell>
            <StyledTableTitleCell align="right"></StyledTableTitleCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{summary[row.code]}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}