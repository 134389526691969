import React, { useState, useEffect } from 'react';
import {  Container, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { BarChartComponent } from './charts';
import { rotateChart, transposeArray } from '../helpers/helper';
import NumericTable from './ui/NumericTable';
import ChartTable from './ui/ChartTable';
import ChartControlComponent from './chartControl';
import LongMenu from './ui/LongMenu';
import axios from 'axios';
import { useParams } from "react-router-dom";

// const resultApi = process.env.REACT_APP_RESULT_API_ENDPOINT;

const ChartComponent = (props) => {
  const { 
    columnNamesObj,
    columnIndex,
    groupData,
    data,
    options,
    groupableColumnResult,
    compareToSeniorColumn,
    compareToJuniorColumn,
    getCompareColumns,
  } = props;
  const [decimalPlace, setDecimalPlace] = useState(8);
  const [transposeStatus, setTransposeStatus] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('white');
  const params = useParams();
  const [showAiForm, setShowAiForm] = useState(false);
  const [ aiData, setAiData ] = useState({
    loading: false,
    message: '',
  });
  const [chartData, setChartData] = useState({
    height: 300,
    xField: 'count',
    yField: 'value',
    autoFit: true,
    isStack: false,
    isGroup: false,
    seriesField: 'value',
    data,
    cDisplayType: '',
    cChartType: 'Bar',
    coordinate: {
      type: 'transpose',
    },
  });
  const [control, setControl] = useState({
    isGrouped: false,
    isStack: false,
    chartType: 'Bar',
    loading: true,
    focus: 1,
    data,
    groupData,
    tableData: [],
    groupStyle: null,
    compareData: null,
    compareState: 0,
    showTable: 0,
    compareWith: -1,
    table: {},
    tableObj: {
      values: [],
      types: [],
    },
  });

  const ChatGBTMaxWords = 35;
  const ChatGBTMaxCharacters = 250;

  const getTitle = () => {
    let title = '';
    if (columnNamesObj[columnIndex]) {
      title = columnNamesObj[columnIndex].name;
    }

    if (control.compareWith >= 0 && columnNamesObj[control.compareWith]) {
      title+= `/${columnNamesObj[control.compareWith].name}`
    }
    return title;
  }

  const [chatText, setChatText] = useState('');

  const [tableView, setTableView] = useState('number');
  const [numberPercentView, setNumberPercentView] = useState({ number: true, percent: false });

  const changeTableView = view => {
    if (view === 'number' && numberPercentView.number && !numberPercentView.percent) return;

    const newstate = { ...numberPercentView };
    newstate[view] = !newstate[view];
    setNumberPercentView({ ...newstate });
    if (numberPercentView[view]) {
      setTableView(view)
    }

    if (numberPercentView.number && numberPercentView.percent) {
      setTableView('both')
    }

    if (!numberPercentView.percent) {
      setTableView('number')
    }
  }

  const getChartData = () => {
    setChartData({ ...chartData, data })
  }

  const setChartGroupStyle = (style) => {
    const key = style === 'isGroup' ? 'isStack' : 'isGroup';
    setChartData({ ...chartData, [style]: true, [key]: false, cDisplayType: style });
  }

  const changeChartType = (option) => {
    if (chartData.cChartType === option) return;

    let xField;
    let yField;
    if (option === 'Column') {
      xField = 'value';
      yField = 'count';
      setChartData({
        ...chartData,
        xField: 'value',
        yField: 'count',
        cChartType: option,
        seriesField: 'count',
        label: {
          position: 'middle', // 'top', 'bottom', 'middle',
          style: {
            fill: '#FFFFFF',
            opacity: 0.6,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        meta: {
          value: {
            alias: 'Value',
          },
          count: {
            alias: 'Count',
          },
        },
  
      });

      // xAxis: {
      //   label: {
      //     autoHide: true,
      //     autoRotate: false,
      //   },
      // },

    }

    if (option === 'Bar') {
      xField = 'count';
      yField = 'value';
      setChartData({ ...chartData, xField, yField, cChartType: option });
    }

    // setControl({ ...control, compareWith: null, tableData: [], compareState });
  }

  const typeChatText = value => {
    // const splits = chatText.split(' ');
    const vSplits = value.split(' ');
    if (vSplits.length < ChatGBTMaxWords && value.length <= ChatGBTMaxCharacters) {
      setChatText(value)
    }
  }

  useEffect(() => {
    getChartData();
    setChatText(`Create a report/summary based on the following ${getTitle()} data`)
  }, [data]);

  const slowDisplay = words => {
    let wordsArr = words.split(' ');
    let counter = 0;
    let message = wordsArr[0];
    const intervalRef = setInterval(() => {
      counter++
      message = message + ' ' + wordsArr[counter];
      setAiData(prev => ({ ...prev, message }));
      if (counter === wordsArr.length - 1) clearInterval(intervalRef);
    }, 150);    
  }

  const randomInt = (max, min) => Math.floor(Math.random() * (max - min + 1)) + min;
  const slowDisplayByChar = words => {
    let len = 0;
    const intervalRef = setInterval(() => {
      len = len + randomInt(10, 2);
      if (len >= words.length - 1) {
        len = words.length;
        clearInterval(intervalRef);
      }
      setAiData(prev => ({ ...prev, message: words.substr(0, len) }));
    }, 25);    
  }

  const genAIReport = async () => {
    const baseURL = `${process.env.REACT_APP_RESULT_API_ENDPOINT}`;
    // let ref = localStorage.getItem('result');
    const ref = params.resultReference;
    if (!ref) return

    let table = groupableColumnResult[columnIndex];
    let compareName = null;
    if (control.compareWith >= 0) {
      table = control.aiTable;
      compareName = columnNamesObj[control.compareWith].name
    }

    const payload = {
      columnIndex,
      columnName: columnNamesObj[columnIndex].name,
      compareName,
      compareIndex: control.compareWith,
      prompt: chatText,
      table,
    }

    const headers = {
      headers: {
        Authorization: `Bearer ${ref}`,
        accessCode: ref.accessCode,
      }
    }
    // if (ref) return headers
    setAiData({ ...aiData, message: '' });

    const words = ``
    let message;
    // slowDisplay(words);
    // slowDisplayByChar(words);

    if (!payload.columnName || !payload.prompt || payload.prompt === '') return;
    setAiData({ ...aiData, loading: true });

    try {
      const { data: { choice } } = await axios.post(`${baseURL}/result/ai/summary`, payload, headers);
      if (choice && choice.text) {
        message = choice.text;
        if (message.length) {
          slowDisplayByChar(message);
        }
      }
      setAiData({ ...aiData, loading: false });
    } catch (error) {
      setAiData({
        ...aiData,
        loading: false,
        message: error.response ? error.response.message : 'An error ocurred'
      });
      console.log('', error);
    }
  }
  
  const compareChart = (compareColumn) => {
    let compareState = 0;
    let showTable = false;
    if (!compareColumn || compareColumn.value) {
      setControl({ ...control, compareWith: null, showTable, tableData: [], compareState, table: {} });
      setChartData({
        ...chartData,
        data,
        isGroup: false,
        isStack: false,
        seriesField: 'value',
        cDisplayType: '',
      });
      return;
    }

    let fcompareFn = compareToSeniorColumn
    compareState = 1;
    if (columnNamesObj[compareColumn.index].numeric) {
      compareState = 2;
      showTable = true;
    }

    let dataArray = [];
    let result = {};
    if (compareColumn.index < columnIndex) {
      fcompareFn = compareToJuniorColumn;
    }
    result = fcompareFn(columnIndex, compareColumn.index, compareState);

    // for (const group in dGroup) {
    //   let groupResut;
    //   // console.log('========> data[counter]: ', data[counter]);
    //   groupResut = dGroup[group][compareColumn.index];
    //   tableData.push({ ...groupResut, category: group, count: data[counter].count });
    //   const { formatted, counts } = formatChartData2(groupResut, group, groupableColumnResult[compareColumn.index]);
    //   dataArray = [...dataArray, ...formatted];
    //   tableValues.push(counts);
    //   // console.log('---->>> groupResut: formatted', groupResut, formatted);
    //   counter++;
    // }

    // console.log('----> result: ', result);
    setControl({
      ...control,
      compareWith: compareColumn.index,
      compareState,
      showTable,
      ...result,
    });

    setChartData({
      ...chartData,
      isGroup: true,
      seriesField: 'type',
      data: result.dataArray,
      cDisplayType: 'isGroup',
    });

    return dataArray;
  }

  const rotatAChart = () => {
    setChartData({ ...chartData, coordinate: { type: rotateChart(chartData.coordinate.type) } })
  }

  const toggleTable = () => {
    setControl({ ...control, showTable: !control.showTable });
  }

  const transposeTableData = () => {
    setTransposeStatus(!transposeStatus);
    getTableHeader()
  }
  const getTableHeader = () => {
    const { tableHeaders, tableValues, rowTitle, headerObj, rowTitleObj } = control.table;
  
    const tabily = {
      transposed: transposeStatus,
      headers: [],
      rowTopics: [],
      values: tableValues,
      totalArray: [],
      transposeTotalArray: [],
    }

    let headers = tableHeaders;
    let rowTopics = rowTitle;
    // let totalArray = Object.values(headerObj);
    let totalArray = headerObj;
    // console.log('::::::::::::::::::::::: headerOBJ ', headerObj, totalArray);
    let transposeTotalArray = Object.values(rowTitleObj);
    let values = tableValues;

    if (transposeStatus) {
      headers = rowTitle;
      rowTopics = tableHeaders;
      totalArray = Object.values(rowTitleObj);
      transposeTotalArray = Object.values(headerObj); 
      values = transposeArray(tableValues);   
    }

    return { ...tabily, headers, rowTopics, totalArray, transposeTotalArray, values };
  }

  return (
    <div>
      {/* {data && <Paper elevation={5}> */}
      <Paper elevation={5} sx={{ p: 2, mb: 4, backgroundColor }}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <h3>{getTitle()}</h3>
        </Grid>
        <Grid item xs={1}>
          
        </Grid>
        <Grid item xs={1}>
        <LongMenu changeChartType={changeChartType} />
        </Grid>
      </Grid>
      
      <Container>
      { chartData.data && control.compareState < 2 && <BarChartComponent
        options={options}
        control={control}
        chartConfig={chartData}
        compareChart={compareChart}
        rotateChart={rotatAChart}
        sx={{ mt: 4 }}
      />
      }

      {/* Groupable columns by Numeric table */}
      { chartData && control.compareState === 2 && control.showTable &&
        control.tableData &&
        <NumericTable sx={{ mt: 4 }} data={control.tableData} decimalPlace={decimalPlace} numberPercentView={numberPercentView} />
      }

      {/* Groupable columns by other columns */}
      { control.compareState === 1 && control.showTable &&
        control.tableData &&
      <ChartTable
        table={control.table}
        tableInfo={getTableHeader()}
        numberPercentView={numberPercentView}
        transposeStatus={transposeStatus}
        sx={{ mt: 4 }}
        />
      }

      { chartData.data && <ChartControlComponent
        getCompareColumns={getCompareColumns}
        columnIndex={columnIndex}
        control={control}
        decimalPlace={decimalPlace}
        setDecimalPlace={setDecimalPlace}
        compareChart={compareChart}
        rotateChart={rotatAChart}
        compareState={control.compareState}
        toggleTable={toggleTable}
        showTable={control.showTable}
        cDisplayType={chartData.cDisplayType}
        setChartGroupStyle={setChartGroupStyle}
        setTableView={setTableView}
        tableView={tableView}
        changeTableView={changeTableView}
        numberPercentView={numberPercentView}
        transposeStatus={transposeStatus}
        setTransposeStatus={setTransposeStatus}
        transposeTableData={transposeTableData}
        setBackgroundColor={setBackgroundColor}
        backgroundColor={backgroundColor}
        setChatText={typeChatText}
        chatText={chatText}
        genAIReport={genAIReport}
        aiData={aiData}
        setShowAiForm={setShowAiForm}
        showAiForm={showAiForm}
        sx={{ mt: 4 }}
      />
      }      
      </Container>
      <Container sx={{ mt: 3, opacity: 0.8, py: 3 }}>
        {aiData.message}
      </Container>
      </Paper>
    </div>
  )

}

export default ChartComponent;