import React, { useState } from 'react';
import axios from 'axios';
import AppBarComponent from './components/ui/AppBarComponent';
import Footer from './components/ui/Footer';
import GeneralTopCard from './components/home/GeneralTopCard';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import { Box, Container, Card, CardContent, CardActions, Typography, Paper, Grid, CardMedia } from '@mui/material';
import { FormControl, InputLabel, OutlinedInput, Button, CircularProgress, LinearProgress }  from '@mui/material';
const resultApi = process.env.REACT_APP_RESULT_API_ENDPOINT;

const ContactForm = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const defaultForm = {
    email: '',
    // title: '',
    subject: '',
    first_name: '',
    last_name: '',
    message: ''
  }
  const [formData, setFormData] = useState({...defaultForm});

  const validateForm = () => {
    setError('');
    for (const key in formData) {
      if (!formData[key] || formData[key] === '') {
        setError("Incomplete form, please fill all required fields.");
        return;
      }
    }
    alert(JSON.stringify(formData));
    sendMessage()
  }
  
  const handleChange = e => setFormData({ ...formData, [e.target.id]: e.target.value  });

  const sendMessage = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`${resultApi}/auth/contact-us`, formData);
      setLoading(false);
      setFormData({...defaultForm});
      setMessage(data.message)

    } catch (error) {
      setLoading(false);
      if (!error.response) return setError('Request failed');
      if (error.response && error.response.message) return setError(error.response.message);
      if (error.response.data && error.response.data.message) return setError(error.response.data.message);
    }
  }

  return (
  <Card sx={{mt: 2}}>
    <CardContent>
      <Typography variant="h6">
        Send us a message <ContactMailOutlinedIcon />
      </Typography>

      <Box sx={{my: 1}}>
      <Typography variant="body2" color="green">
        {message}
      </Typography>
      </Box>
    </CardContent>
    <CardContent>
      <FormControl fullWidth sx={{ mt: 1 }}>
        <InputLabel htmlFor="first_name">First name</InputLabel>
        <OutlinedInput
          value={formData.first_name}
          onChange={handleChange}
          label="First name"
          id="first_name"
          size="small"
          color='secondary'
          fullWidth
        />
      </FormControl>

      <Box sx={{ mt: 3 }}>
        <FormControl fullWidth>
        <InputLabel htmlFor="last_name" aria-describedby="last_name">Last name</InputLabel>
        <OutlinedInput
          value={formData.last_name}
          onChange={(e)=>handleChange(e)}
          label="Last name"
          size="small"
          id="last_name"
          color='secondary'
          fullWidth
        />
      </FormControl>
      </Box>

      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel htmlFor="subject">Subject</InputLabel>
        <OutlinedInput
          value={formData.subject}
          onChange={handleChange}
          label="Subject"
          id="subject"
          color='secondary'
          fullWidth
        />
      </FormControl>

      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel htmlFor="subject">email</InputLabel>
        <OutlinedInput
          type='email'
          value={formData.email}
          onChange={handleChange}
          label="email"
          id="email"
          fullWidth
          color='secondary'
          height={4}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel htmlFor="subject">Message</InputLabel>
        <OutlinedInput
          type='text'
          value={formData.message}
          onChange={handleChange}
          label="message"
          id="message"
          fullWidth
          color='secondary'
          multiline
          rows={6}
        />
      </FormControl>


    </CardContent>
      <div>
        <Box sx={{color: 'red', mx: 2}}>
          {error}
        </Box>
      </div>

    <CardActions>

      <Grid container>
        <Grid item xs={4} sm={4} md={8}>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Button variant="outlined" color="secondary" onClick={validateForm}>
            {loading && <CircularProgress color="warning" size={20} /> }
            Contact us
          </Button>          
        </Grid>
      </Grid>
    </CardActions>
    {/* <LinearProgress /> */}
  </Card>)
}



const ContactUs = () => {
  return (
    <Box>
      <AppBarComponent />
      <Box>
        <GeneralTopCard params={{ splitCount: 1, height: 150, heading: 'Contact us here' }} />
        <Box>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={4} md={4}>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <ContactForm />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
            </Grid>
          </Grid>

        </Box>
      </Box>

      <Box sx={{height: 50}}></Box>
      <Footer />
    </Box>
  )
}

export default ContactUs;