import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { dC } from '../../helpers/helper';

export default function NumericTable(props) {
  const { data, decimalPlace } = props;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell><b>Categories</b></TableCell>
            <TableCell align="right"><b>No</b>&nbsp;</TableCell>
            <TableCell align="right"><b>Min</b>&nbsp;</TableCell>
            <TableCell align="right"><b>Max</b>&nbsp;</TableCell>
            <TableCell align="right"><b>Mean</b>&nbsp;</TableCell>
            <TableCell align="right"><b>Median</b>&nbsp;</TableCell>
            <TableCell align="right"><b>StdDev</b>&nbsp;</TableCell>
            <TableCell align="right"><b>Q25</b>&nbsp;</TableCell>
            <TableCell align="right"><b>Q50</b>&nbsp;</TableCell>
            <TableCell align="right"><b>Q75</b>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <b>{row.category}</b>
              </TableCell>
              <TableCell component="th" scope="row">
                {row.count}
              </TableCell>
              <TableCell align="right">{dC(row.Min, decimalPlace)}</TableCell>
              <TableCell align="right">{dC(row.Max, decimalPlace)}</TableCell>
              <TableCell align="right">{dC(row.Mean, decimalPlace)}</TableCell>
              <TableCell align="right">{dC(row.Median, decimalPlace)}</TableCell>
              <TableCell align="right">{dC(row.StdDev, decimalPlace)}</TableCell>
              <TableCell align="right">{dC(row.Q25, decimalPlace)}</TableCell>
              <TableCell align="right">{dC(row.Q50, decimalPlace)}</TableCell>
              <TableCell align="right">{dC(row.Q75, decimalPlace)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
