import React, { useState, useEffect } from 'react';
import AppBarComponent from './components/ui/AppBarComponent';
import Footer from './components/ui/Footer';
import PricingCard from './components/ui/PricingCard';
import { Container, Paper, Grid, Box } from '@mui/material';
import GeneralTopCard from './components/home/GeneralTopCard';
import axios from 'axios';
const resultApi = process.env.REACT_APP_RESULT_API_ENDPOINT;

function Pricing() {
  const [pricing, setPricing] = useState([
    {name: 'free'},
    {name: 'starter'},
    {name: 'advance'},
    {name: 'senior'}
  ]);

  const getPlans = async () => {
    try {
      const { data: { plans } } = await axios.get(`${resultApi}/services/plans`);
      setPricing(plans);
      return plans;
    } catch (error) {
      console.log('error ', error);
    }
  }

  useEffect(()=>{
    async function loadPlans () {
      const result = await getPlans();
      // setPricing(result);
    }
    loadPlans()
  }, []);

  const goTo = function (page) {
    alert(page)
    // https://app.evoked.io?p=${page.toLowerCase()}
  }

  return (
    <div>
      <AppBarComponent />
      <GeneralTopCard params={{
        height: 380,
        heading: `Join hundreds of businesses comprising thousands of team members.`,
        // description: `Get started for free`,
        splitCount: 1,
        bgColor: '#f9f9f9',
        }} />
      
      <Container style={{ marginTop: '25px' }}>
        {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {pricing && pricing.map((priceObj, index) => (
            <Grid item xs={12} sm={4} md={3} key={index}>
              <Paper elevation={index * 2}  key={index}>
                <PricingCard {...priceObj} />
              </Paper>
            </Grid>
          ))}
        </Grid> */}

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {pricing && pricing.map((priceObj, index) => (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <Paper elevation={index * 2}  key={index}>
              {/* <div style={{ fontSize: 20, fontWeight: 700 }}></div> */}
                {pricing.length && <PricingCard {...priceObj} />}
              </Paper>
            </Grid>
          ))}
        </Grid>


      </Container>
      <Box sx={{ height: 40 }}></Box>
      <Footer />
    </div>
  );
}

export default Pricing;
