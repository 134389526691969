import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {  Paper, Grid } from '@mui/material';
// import accountImage from '../media/accounting-gcb3dc8544_640.png';
// import slackAnalyzeResult1 from '../media/slack_analyze_result1.png';
import slackAnalyzeResult2 from '../media/slack_analyze_result3.png';


const features = [
  { name: 'Free!', note: 'Get started free, no credit card required, though with limited features', img: null },
  { name: null, note: null },
  { name: '', note: '' },
  { name: 'Drop data, wait and view results', note: 'No technical skills required.', img: null },
  { name: null, note: null },
  { name: null, note: null },
  { name: 'Multi-platform support', note: 'Connect with your favorite work tools like Slack, Discord, with more platforms coming soon', img: null },
  { name: null, note: null },
  { name: null, note: null },
  { name: 'Interactive Infographics', note: 'Visualize analyzed data and perform live comparative analysis', img: null },
  { name: null, note: null },
  { name: null, note: null },
  { name: 'Chat GPT AI', note: 'Generate report/summary on results of analysis by leveraging the power of Artificial Intelligence', img: null },
]

const featureCard = (feature) =>(
  <React.Fragment>
  <Paper elevation={4} sx={{mt: 4, spacing: 3}}>
    <CardContent sx={{padding: 2 }} >
        <div>
          <Typography sx={{ fontSize: 16, fontWeight: 700, mt: 1 }} color="text.secondary" gutterBottom>
          {feature.name}
          </Typography>
        </div>
        <div>
        <Typography sx={{ mb: 1.5, mt: 1, fontSize: 15 }} color="text.secondary">
        {feature.note}
        </Typography>                  
        </div>
    </CardContent>
    </Paper>
  </React.Fragment>
);


const card = (
  <React.Fragment>
    <CardContent sx={{padding: 10, minHeight: 400}}>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4} sm={8} md={7}>
          <Box sx={{mt: 4}}>
            <Paper elevation={0}>
            <img src={slackAnalyzeResult2} alt="top-image2" />
            </Paper>
          </Box>
        </Grid>

        <Grid item xs={4} sm={8} md={5}>
        <Grid container spacing={2}>
          {
            features.map((feature, index) =>(
              <Grid item xs={feature.name? 8 : 4} key={index}>
              { feature.name? featureCard(feature) : <div></div>}
              </Grid>
            ))
          }
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
    {/* <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions> */}
  </React.Fragment>
);

export default function SecondCard() {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card elevation={9}>{card}</Card>
    </Box>
  );
}
