import React from 'react';
import AppBarComponent from './components/ui/AppBarComponent';
import Footer from './components/ui/Footer';
import { Box, Container, Paper, Grid } from '@mui/material';
import PrivacyHtml from './components/ui/Privacy-Html';

const PrivacyPolicy = () => {
  return (
    <div>
      <AppBarComponent />
        <Box sx={{ minHeight: 450 }}>
          <Container>
            <h3>Our Privacy policy</h3>
            <PrivacyHtml />
          </Container>
        </Box>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy;