import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function FileSummary(props) {
  const { data } = props;

  const order = [
    [{ name: 'File name', align: "right", id: 'file_name' }, { name: 'Date', id: 'date', align: "right" }],
    [{ name: 'File size', id: 'file_size', align: "right" }, { name: 'Platform', id: 'platform', align: "right" }],
    [{ name: 'Team', id: 'team', align: "right" }, { name: 'File type', id: 'file_type', default: 'csv', align: "right" }],
    [{ name: 'Records size', id: 'row_size', align: "right" },  { name: 'Column size', id: 'column_size', align: "right" }],
    [{ name: 'Continuous columns', id: 'continuous_columns', align: "left" }, { name: 'Categorical columns', id: 'categorical_columns', align: "left" }],    
  ];

  const getValue = (id) => {
    if (['platform', 'file_name', 'file_size', 'file_type', 'team', 'row_size', 'column_size', 'date'].includes(id)) {
      return data[id];
    }

    let s = '';
    if (id === 'categorical_columns') {
      data.result.GroupableStringColumnNames.forEach((n, i) => {
        if (i < data.result.GroupableStringColumnNames.length - 1) {
          s+= `${n}, `;
        } else {
          s+= `${n}`;
        }
      });
      // return s;
    }

    if (id === 'continuous_columns') {
      data.result.NumberColumnIndexs.forEach((n, i) => {
        if (i < data.result.NumberColumnIndexs.length - 1) {
          s+= `${data.result.ColumnNames[n]}, `;
        } else {
          s+= `${data.result.ColumnNames[n]}`;
        }
      });
      // return s;
    }
    return s;
  }

  return (
    <div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 550 }} aria-label="caption table">
        {/* <caption>A basic table example with a caption</caption> */}
        <TableBody>
          {order.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <b>{row[0].name}</b>
              </TableCell>
              <TableCell align={row[0].align}>{getValue(row[0].id)}</TableCell>
              <TableCell component="th" scope="row">
              <b>{row[1].name}</b>
              </TableCell>
              <TableCell align={row[1].align}>{getValue(row[1].id)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}