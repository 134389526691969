import React, { useRef } from 'react'
import { BarChart, PieChart, ColumnChart } from '@opd/g2plot-react'; // ColumnChart

const BarChartComponent = (props) => {
  const { chartConfig} = props;

  // https://g2plot.antv.vision/en/docs/manual/plots/column

  const chartRef = useRef()
  return <div>
    {chartConfig.cChartType === 'Bar' && <BarChart {...chartConfig } chartRef={chartRef} /> }
    {chartConfig.cChartType === 'Pie' && <PieChart {...chartConfig } chartRef={chartRef} /> }
    {chartConfig.cChartType === 'Column' && <ColumnChart {...chartConfig } chartRef={chartRef} /> }
    </div>
}

export default BarChartComponent;

// References
// https://g2plot.antv.vision/en/examples/bar/stacked#basic
