import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectDecimal(props) {
  const { decimalPlace, setDecimalPlace, width } = props;
  const list = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <FormControl sx={{ m: 1, minWidth: width || 100 }} size="small">
      <InputLabel id="demo-select-small">Select decimal place</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={decimalPlace}
        label="Decimal place"
        sx={{ maxHeight: 30 }}
        onChange={(e) =>setDecimalPlace(e.target.value)}
      >
        { list && list.map((val, index) => (
          <MenuItem key={index} value={val}>{val}</MenuItem>
        )) }
      </Select>
    </FormControl>
  );
}
