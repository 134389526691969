import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import {CardContent, CardMedia} from '@mui/material';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
// import accountImage from '../media/accounting-gcb3dc8544_640.png';
// import slackAnalyzeResult1 from '../media/slack_analyze_result1.png';
// import slackAnalyzeResult2 from '../media/slack_analyze_result3.png';
import speedImg from '../media/flats/icons/performance.svg';
import noCodeImg from '../media/flats/icons/snap.svg';
import aiImg from '../media/flats/icons/artificial-intellegence.svg';
import freeImg from '../media/flats/icons/no-credit-card.svg';
// import noCodeImg from '../media/flats/icons/no-code.svg';
import multiPlatformIng from '../media/flats/icons/device.svg';
import infographicsImg from '../media/flats/icons/online-analytical.svg';

const features = [
  { name: 'No skills required', note: 'Drop data, wait and view results.\nEveryone in the team can quickly make meaning of their data', img: noCodeImg },
  { name: 'Get started free', note: 'No credit card required, though with limited features', img: freeImg },
  { name: 'Multi-platform support', note: 'Connect with your favorite work tools like Slack, Discord, with more platforms coming soon', img: multiPlatformIng },
  { name: 'Interactive Infographics', note: 'Visualize analyzed data and perform live comparative analysis', img: infographicsImg },
  { name: 'Chat GPT AI', note: 'Generate report/summary on results of analysis by leveraging the power of Artificial Intelligence', img: aiImg },
  {name: 'Super fast delivery', note: 'Our robust infrastructure ensures your data is analyzed at blazing speed', img: speedImg }
]

const featureCard = (i) =>(
  <Box>
  {/* <Paper elevation={0} sx={{mt: 1, spacing: 3}}> */}
    {/* <Grid container xs={12} sm={12} md={12}>
      <Grid item xs={12} sm={12} md={6}>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
      </Grid>
    </Grid> */}
    <Box sx={{px: 10}}>
      { CardImg(i) }
    </Box>

    <Box sx={{padding: 1 }} >
        <div>
          <Typography variant='h4' sx={{ fontSize: 16, fontWeight: 700, mt: 1, color: 'white' }} color="text.secondary" gutterBottom>
          {features[i].name}
          </Typography>
        </div>
        <Box sx={{pr: 8}}>
        <Typography sx={{ mb: 1.5, mt: 1, fontSize: 18, color: 'white' }}>
        {features[i].note}.
        </Typography>                  
        </Box>
    </Box>
    {/* </Paper> */}
  </Box>
);

const CardImg = i => (
  <CardMedia>
    <img src={features[i].img} alt={features[i].name} height={100} width={100} />
  </CardMedia>
);


const card = (
  <React.Fragment>
    <CardContent sx={{padding: 1, minHeight: 400, backgroundColor: 'black'}}>
      <Box sx={{mt: 6}}>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={12} sm={12} md={4}></Grid>
      <Grid item xs={12} sm={12} md={4}>
      <Typography sx={{ mb: 1.5, mt: 1, color: 'white', fontWeight: 900 }} variant='h4'>
        Features you'd love
      </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4}></Grid>
      </Grid>
      </Box>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      {/* <Grid item xs={4} sm={8} md={7}>
          <Box sx={{mt: 4}}>
            <Paper elevation={0}>
            <img src={slackAnalyzeResult2} alt="top-image2" />
            </Paper>
          </Box>
        </Grid> */}
        <Grid item xs={4} sm={8} md={1}></Grid>

        <Grid item xs={4} sm={8} md={11}>
        <Grid container xs={12} sm={12} md={12} sx={{my: 4}}>
          {
            features.map((feature, index) =>(
              <Grid item xs={12} sm={12} md={6} key={index} sx={{my: 4, pr: 1}}>
                {/* { CardImg(index) } */}
                <Box>
                  { featureCard(index) }
                </Box>
              </Grid>
            ))
          }
          </Grid>
        </Grid>
        {/* <Grid item xs={4} sm={8} md={1}>hhh</Grid> */}
      </Grid>
    </CardContent>
    {/* <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions> */}
  </React.Fragment>
);

export default function FeatureSection() {
  return (
    <Box sx={{ minWidth: 275, backgroundColor: 'black' }}>
      <Card elevation={9}>{card}</Card>
    </Box>
  );
}
