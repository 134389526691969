// import logo from './logo.svg';
import React  from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes  } from '@mui/material/styles';
import './App.css';
import { Routes, Route } from "react-router-dom";
import About from './views/About';
import Home from './views/Home';
import Pricing from './views/Pricing';
import Result from './views/Result';
import Features from './views/Features';
import Faqs from './views/Faqs';
import RedirectSlack from './views/RedirectSlack'; 
import GoogleLogin from './views/components/auth/google';
import SocialLogin from './views/components/auth/SocialLogin';
import Integrations from './views/Integrations';
import PrivacyPolicy from './views/Privacy';
import Terms from './views/Terms';
import ContactUs from './views/ContactUs';
// import SlackIntegration from './views/pages/integrations/Slack';
// import DiscordIntegration from './views/pages/integrations/Discord';
import IntegrationPage from './views/pages/integrations/Index';

// https://adamtuttle.codes/blog/2022/scaling-fargate-based-on-sqs-queue-depth/
let theme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: [
      'Work Sans',
      'Source Sans Pro',
      'sans-serif',
      // '-apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
    lineHeight: 32,
    fontSize: 16,
    // fontWeightMedium: 900,
  },
  palette: {
    appBar: {
      light: '#757ce8',
      main: '#fff',
      dark: '#002884',
      contrastText: '#00000',
    }
  },
});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/features" element={<Features />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/integrations/:app" element={<IntegrationPage />} />
        {/* <Route path="/integrations/" element={<Integrations />} /> */}
        <Route path="/oauth/3rd-party/slack" element={<RedirectSlack />} />
        <Route path="/public/result/:resultReference" element={<Result />} />
        <Route path="/result/:resultReference" element={<Result />} />
        <Route path="/demo/result/:resultReference" element={<Result />} />
        <Route path="/auth/google" element={<GoogleLogin />} />
        <Route path="/auth/social" element={<SocialLogin />} />

      </Routes>

    </ThemeProvider>
  );
}

export default App;
