import * as React from 'react';
import {Box, Container, Card, CardContent, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
// import DigitalPresentation from '../media/storyset/Digital_presentation-bro.png';
// import DigitalPresentation2 from '../media/storyset/Metrics-rafiki.png';
import DigitalPresentation3 from '../media/storyset/Business-Plan-amico.png';
import businessSolution1 from '../media/storyset/svgs/Business-solution.svg';
import businessSolution2 from '../media/storyset/svgs/Business-mission.svg';
import ChartGif from '../media/animation/chart_gif3.gif';
import CountUp from 'react-countup';
import styles from './styles/styles.module.css';
import '../../styles/website.css';

const AppName = process.env.REACT_APP_APP_NAME;

const countUpSection = (
  <React.Fragment>
    <Box className='fnt' sx={{ mt: 6 }}>
      <Grid container>
      <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          <div className={styles.countUp}>
          <Typography sx={{ mb: 1, mt: 2, fontSize: 37, fontWeight: 900, display: 'inline-block', transform: 'scale(0.9)' }} color="text.secondary">
            Companies
          </Typography>
          <div>
            <CountUp delay={5} end={5000} /> +
          </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ mb: 1, mt: 2, fontSize: 30, fontWeight: 900, display: 'inline-block', transform: 'scale(0.9)' }} color="text.secondary">
            Data processed
          </Typography>
          <div sx={{my: '2px'}}>
            <CountUp delay={0.5} end={1000000} start={10} duration={3.5} className={styles.countUp} /> +
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      </Box>
  </React.Fragment>
)

// F6F1F1
const card = (
  <React.Fragment>
    <CardContent sx={{padding: 6, minHeight: 400, backgroundColor: '#F9FAFF'}}>
    <Box>
        <Container sx={{my: 4, px: 6, py: 4}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="h4" component="span" fontWeight={900}>
                The most <Typography variant="h4" component="span" fontWeight={700} color={`#6731D4`}>
                interactive <br /> UI </Typography> 
                you can imagine
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}></Grid>
          </Grid>
        </Container>
      </Box>

      {/* <Box>
        <Container sx={{my: 4, px: 6, py: 4}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={10}>
              <Box sx={{mt: 8}}>
                <img src={ChartGif} alt="Chart gif"  width={'100%'} />
              </Box>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
          </Grid>
        </Container>
      </Box> */}



      <Grid container spacing={2} sx={{mt: 4}}>
      <Grid item xs={1}></Grid>
        <Grid item xs={12} md={7}>
        <img src={ChartGif} alt="Chart gif"  width={'90%'} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{mt: 6, width: 360}}>
          <Typography sx={{ mb: 1.5, mt: 1, fontSize: 18, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
            Evoke Insight is the first real time data analyzing tool engineered to integrate with multiple workspaces for teams and businesses.
          </Typography>
          <Typography sx={{ mb: 1.5, mt: 1, fontSize: 18, lineHeight: 2 }} color="text.secondary" fontWeight={100}>
            In addition to the obvious benefits of data driven decision making in business, Evoke Insight takes the lead in providing
            a no code, no task, data analyzing tool that delivers at blazing fast speed.
          </Typography>
  
          </Box>
        </Grid>
        <Grid item xs={1}>&nbsp;</Grid>
      </Grid>

      {/* <Grid container spacing={2} sx={{mt: 8}}>
      <Grid item xs={1}>&nbsp;</Grid>
        <Grid item xs={5}>
        <div>
          <Typography sx={{ mb: 1.5, mt: 1, fontSize: 18, lineHeight: 2 }} color="text.secondary">
            <b>{AppName}</b> does the heavy lifting in analyzing data thereby allowing businesses and professionals focus
            on what they do best by providing them with actionable insights they need to improve decision-making and
            continually update their business strategy.
          </Typography>
          </div>
        </Grid>
        <Grid item xs={5}>
          <Container>
            <img src={businessSolution2} height={300} width={300} alt="Digital presentation" />
          </Container>
        </Grid>
        <Grid item xs={1}>&nbsp;</Grid>
      </Grid> */}


    </CardContent>

  </React.Fragment>
);


export default function CustomerCard() {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}
