import React from 'react';

function About() {
  return (
    <div className="">
        <h2>.</h2>
    </div>
  );
}

export default About;
