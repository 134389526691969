import * as React from 'react';
import {Box, Card, CardContent, Typography, Paper, Grid, Container, CardMedia} from '@mui/material';
import FaqAccordion from './FaqAccordion';

const FaqSection = () => {

  // const sampleLink1 = 'https://evoked.io/result/sample-data-1';
  const sampleLink = 'https://evoked.io/result/0d41422d-fef7-4235-b45c-f7edc1ed9eda'
  const faqs = [
    { q: 'Do I need a credit card to signup', a: 'No! You can signup for free and still be able to analyze small dataset' },
    { q: 'What type of data analysis can I get', a: 'You will get summary statistics, comparative analysis, etc' },
    { q: 'How do I use AI/ChatGPT', a: 'When you analyze data, you will be provided with a unique link with an access code. Take a look at the result on this link' },
    { q: 'Can I see a sample result', a: `Yes please! Take a look at the result on the following link and use 'aaaaa' as the access code.`, link: sampleLink },
  ];

  return (
    <Box>
      <Box sx={{ px: 4, mt: 10}}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} sx={{my: 4}}>
            <Grid item xs={12} sm={1} md={4}></Grid>
            <Grid item xs={12} sm={6} md={7}>
            <Typography sx={{ mb: 1.5, mt: 1, fontWeight: 600 }} variant='h6'>
              Frequently asked questions 
            </Typography>
            </Grid>
        </Grid>

        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={12} sm={6} md={3}></Grid>
            <Grid item xs={12} sm={6} md={7}>
              <FaqAccordion faqs={faqs} />
            </Grid>
        </Grid>
      </Box>
    </Box>
  )
  
}

export default FaqSection;