import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import {Button, Box, Container} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { fadeIn, bounce, slideInLeft, slideInRight, tada } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import Data_report_amico from '../media/storyset/svgs/Data_report_amico.svg';


const animationStyles = {
  bounce: {
    animation: 'x 2s',
    animationName: Radium.keyframes(bounce, 'bounce')
  },
  fadeIn: {
    animation: 'x 2s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')    
  },
  slideInLeft: {
    delay: 4,
    animation: 'x 3s',
    animationName: Radium.keyframes(slideInLeft, 'slideInLeft')    
  },
  slideInRight: {
    delay: 0,
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInRight, 'slideInRight')    
  },
  slideInRight2: {
    delay: 8,
    animation: 'x 6s',
    animationName: Radium.keyframes(slideInRight, 'slideInRight')    
  },

  tada: {
    delay: 4,
    animation: 'x 8s',
    animationName: Radium.keyframes(tada, 'tada')    
  }
};

const Tl = ({ inViewport, forwardedRef, enterCount  }) => (
  // const { inViewport, forwardedRef, enterCount } = props;
  <StyleRoot>
  <Timeline position="alternate">
  <TimelineItem>
    {/* <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      align="right"
      variant="body2"
      color="text.secondary"
    >
      9:30 am
    </TimelineOppositeContent> */}
    <TimelineSeparator sx={{ height: 150 }}>
      <TimelineConnector />
      <TimelineDot>
        <FastfoodIcon />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      {
        inViewport &&  (
          
          <div style={animationStyles.slideInRight}>
            <Typography variant="h4" component="span">
              Share Data
            </Typography>
            <Typography>
            With Evoke Insight bot already added to your favourite workspace (Slack, Discord, etc),
            you can share data mostly in form of CSV with the bot by tagging the bot in a message.
            </Typography>
          </div>
        )
      }
    </TimelineContent>
    {/* <TimelineSeparator sx={{height: 300}}></TimelineSeparator> */}
  </TimelineItem>

    {/* <TimelineSeparator sx={{height: 300}}></TimelineSeparator> */}
    {/* <TimelineConnector sx={{height: 200}} /> */}
    {/* <TimelineSeparator></TimelineSeparator> */}


  <TimelineItem>
    {/* <TimelineOppositeContent
      sx={{ m: 'auto 0' }}
      variant="body2"
      color="text.secondary"
    >
      10:00 am
    </TimelineOppositeContent> */}
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot color="primary">
        <LaptopMacIcon />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      {
        inViewport && (
          <div style={animationStyles.slideInLeft}>
            <Typography variant="h4" component="span">
            Blink!
            </Typography>
            <Typography>
              Yes Blink! Experience super fast data analysis with our reliable dedicated data analysis infrastructure.
              Of course, duration of analysis would vary depending on size of data. 
            </Typography>
          </div>
        )
      }
    </TimelineContent>
  </TimelineItem>
  <TimelineItem>
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot color="primary" variant="outlined">
        <HotelIcon />
      </TimelineDot>
      <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      {
        inViewport && (
          <div style={animationStyles.slideInRight2}>
            <Typography variant="h6" component="span">
              View result
            </Typography>
            <Typography>
              Get instant link to view result of data analysis.
            </Typography>
          </div>
        )
      }
    </TimelineContent>
  </TimelineItem>
  {/* <TimelineItem>
    <TimelineSeparator>
      <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      <TimelineDot color="secondary">
        <RepeatIcon />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent sx={{ py: '12px', px: 2 }}>
      <Typography variant="h6" component="span">
        Repeat
      </Typography>
      <Typography>Because this is the life you love!</Typography>
    </TimelineContent>
  </TimelineItem> */}
</Timeline>
</StyleRoot>
);

export default function TimelineComponent( props) {
  const { inViewport, forwardedRef, enterCount } = props;
  // console.log('---- timelixesss: ', props)

  return (
    <Box sx={{ p: 4 }}>
    <Container sx={{py: 4}}>
      <Grid container spacing={2}>
        <Grid item="true" xs={4}>&nbsp;</Grid>
        <Grid item="true" xs={5}>
        <Typography variant="h4" component="span">
          <b>Share & View</b>
        </Typography>
        </Grid>
        <Grid item="true" xs={3}>&nbsp;</Grid>
      </Grid>
    </Container>

    <Container sx={{mt: 3}} ref={forwardedRef}>
      <Grid container spacing={2}>
        <Grid item="true" xs={1}>&nbsp;</Grid>
        <Grid item ="true" xs={10}>
          <Tl inViewport={inViewport} enterCount={enterCount} />
        </Grid>
        <Grid item="true" xs={1}>&nbsp;</Grid>
      </Grid>
    </Container>
  </Box>
  );
}