import * as React from 'react';
import {Card, Box, Grid} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import multiPlatformIng from '../media/flats/icons/device.svg';

export default function FeatureCard({ feature, img }) {
  return (
    <Box>
      <Box>
      <Grid container xs={12} sm={12} md={12} sx={{my: 4}}>
          <Grid item xs={12} sm={12} md={2}>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
          <Box align='center'>
              <img src={img} alt={feature.name} height={120} width={120} />
            </Box>

          </Grid>
      </Grid>
      </Box>
    <Card sx={{ maxWidth: 365 }} elevation={0}>
        {/* <CardMedia
          component="img"
          height="140"
          image={feature.img}
          alt="green iguana"
        /> */}

      <CardActionArea>
        <CardContent sx={{height: 150, py: 2}}>
          <Box align='center' sx={{px: 2, pl: 8}}>
            <Typography gutterBottom variant="h5" component="div">
              {feature.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {feature.note}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
    </Box>
  );
}
