import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { fadeIn, bounce, slideInLeft, slideInRight, tada } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import '../../styles/website.css';

import Data_report_amico from '../media/storyset/svgs/Data_report_amico.svg';
import PurpleLogo from '../media/SvgjsG1737.png';
import CheckMark from '../media/Frame_528395.png';

const AppName = process.env.REACT_APP_APP_NAME;

const animationStyles = {
  bounce: {
    animation: 'x 2s',
    animationName: Radium.keyframes(bounce, 'bounce')
  },
  fadeIn: {
    animation: 'x 2s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')    
  },
  slideInLeft: {
    animation: 'x 2s',
    animationName: Radium.keyframes(slideInLeft, 'slideInLeft')    
  },
  slideInRight: {
    animation: 'x 2s',
    animationName: Radium.keyframes(slideInRight, 'slideInRight')    
  },
  tada: {
    delay: 2,
    animation: 'x 8s',
    animationName: Radium.keyframes(tada, 'tada')    
  }
};

const hows = [
  { title: 'Share Data', body: 'With Evoke Insight bot already added to your favorite workspace (Slack, Discord, etc), you can share CSV/Excel data with the bot by tagging the bot in a message.' },
  { title: 'Blink!', body: 'Yes Blink! Experience super fast data analysis with our reliable dedicated data analysis infrastructure. Of course, duration of analysis would vary depending on size of data.' },
  { title: 'View result', body: 'Get instant link to view result of data analysis.' },
]

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//   >
//     •
//   </Box>
// );

const card = (inViewport, enterCount) => (
  <React.Fragment>
    <CardContent sx={{padding: 10, minHeight: 400, backgroundColor: '#ECF2FF' }}>
    <Grid container justify = "center">
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <Typography sx={{fontWeight: 900 }} variant='h3' color="text.secondary" gutterBottom>
          Share & View!
        </Typography>
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>

    {<Grid container justify = "center" sx={{mt: 5}}>
      <Grid item xs={4} sx={{p: 4}}>
        <div>
          {/* Image */}
        </div>
        <div>
        <Typography sx={{ fontSize: 28, fontWeight: 900 }} color="text.secondary" gutterBottom>
          Share Data
        </Typography>

          <Typography sx={{ fontSize: 18, mt: 3 }} spacing={7} color="text.secondary" gutterBottom>
            With {AppName} bot already added to your workspace (Slack, Discord, etc), share data mostly in form of CSV
            with {AppName} bot by tagging the bot.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4} sx={{p: 4}}>
        <Typography sx={{ fontSize: 28, fontWeight: 900 }} color="text.secondary" gutterBottom>
          Blink
        </Typography>
        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
          Yes blink!
        </Typography>
      </Grid>
      <Grid item xs={4} sx={{p: 4}}>
        <div style={enterCount === 1 ? animationStyles.slideInRight : {}}>
          {inViewport && <img src={Data_report_amico} style={animationStyles.tada} alt="Generate data report" /> }
        </div>
        <Typography sx={{ fontSize: 28, fontWeight: 900 }} color="text.secondary" gutterBottom>
          View result
        </Typography>
        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
          You will be provided a link to visualize infographics of the analysis and also access summary data
          where applicable.
        </Typography>
      </Grid>
    </Grid>}
    </CardContent>
  </React.Fragment>
);

export default function HowItWorks(props) {
  const { inViewport, forwardedRef, enterCount } = props;
  return (
    <StyleRoot>
      <Box sx={{backgroundColor: 'black', color: 'whitesmoke'}}>
      <Box sx={{minHeight: 40}}>{`${`
        
      `}`}</Box>

      <Box sx={{minHeight: 100, px: 4, py: 2, mt: 4, display: { xs: 'block', md:'none' }}}>
        <Box>
        <Typography align='center' gutterBottom variant="h3" sx={{color: '#6731D4', py: 0, fontWeight: 300}}>
          Analyze data in
        </Typography>              
        <Typography align='center' gutterBottom variant="h3" sx={{color: '#6731D4', py: 0, fontWeight: 300}}>
          3 easy steps
        </Typography>
        </Box>

      </Box>
        <Box sx={{minHeight: 140, display: { xs: 'none', md:'block' }}}>
          <Box sx={{mt: 8}}>
          <Grid container xs={12} sm={12} md={12}>
            <Grid item md={3}></Grid>
            <Grid item xs={12} sm={12} md={6} sx={{my: 3, pr: 1, py: 0}}>
            <Typography gutterBottom variant="h3" sx={{color: '#6731D4', py: 0, fontWeight: 300}}>
              Analyze data
            </Typography>              
            </Grid>
          </Grid>

          </Box>

          <Grid container xs={12} sm={12} md={12} sx={{my: -5}}>
          <Grid item md={5}></Grid>
            <Grid item xs={12} sm={4} md={4} sx={{my: 3, pr: 1, py: 0}}>
            <Typography gutterBottom variant="h3" sx={{color: '#6731D4', fontWeight: 300, ml: 5}}>
              in
            </Typography>
            </Grid>
            <Grid item md={1}></Grid>
          </Grid>

          <Grid container xs={12} sm={12} md={12} sx={{my: -5}}>
          <Grid item md={6}></Grid>
            <Grid item xs={6} sm={6} md={6} sx={{my: 3, pr: 1, py: 2}}>
            <Typography gutterBottom variant="h3" sx={{color: '#6731D4', fontWeight: 300}}>
              3 easy steps
            </Typography>            
            </Grid>
          </Grid>
        </Box>

        {/* align='center' */}
        <Grid container xs={12} sm={12} md={12} sx={{my: 4}}>
          <Grid item md={1}></Grid>
          <Grid item xs={12} sm={12} md={5} sx={{my: 3, pr: 1}}>
            <Box sx={{px: 5, display: { xs: 'none', md:'block' }}}>
              <img src={PurpleLogo} height={550} />
            </Box>
            <Box sx={{px: 5, display: { xs: 'block', md:'none' }}}>
              <img src={PurpleLogo} height={380} />
            </Box>

          </Grid>

          <Grid item md={4}>
            <Box sx={{px: 10}}>
            { hows.map((how, index)=>(
              <Grid container xs={12} sm={12} md={12} sx={{ my: 4, py: 1 }}>
                <Grid item md={1}><img src={CheckMark} alt='check' /></Grid>
                <Grid item md={11}>
                  <Box key={index} >
                  <Typography gutterBottom variant="h5" component="div" sx={{color: 'whitesmoke'}}>
                     {how.title}
                  </Typography>
                  <Box sx={{ minWidth: 275 }}>
                    <Typography variant="body2" color="whitesmoke" component="div">
                      {how.body}
                    </Typography>
                  </Box>
                </Box>
                </Grid>
              </Grid>
            )) }
            </Box>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
        <Box sx={{height: 100}}></Box>
      </Box>
    {/* <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined" ref={forwardedRef} className='fnt'>{card(inViewport, enterCount)}</Card>
    </Box> */}
    </StyleRoot>
  );
}
