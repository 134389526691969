import React from 'react';
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  Paper,
  Grid,
  CardMedia,
} from '@mui/material';


const GeneralTopCard = ({ params: {
  height = 400,
  bgColor = 'white',
  title,
  heading = 'Evoke Insight',
  description = '',
  img,
  page = 'home',
  splitCount = 2,
} }) => {

  return (
    <Box sx={{ height, backgroundColor: bgColor }}>
      <Box>
        {
          splitCount == 2 && (
            <Container style={{ marginTop: '25px' }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={4} md={6}>
                <Box sx={{ mt: 4 }}>
                  <Typography variant='h4'>
                    { heading }
                  </Typography>
                  <Typography variant='h6'>
                    { description }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sm={4} md={6}>
                { img && (<img src={img} alt={page} /> ) }
              </Grid>
            </Grid>
          </Container>    
          )}

          {
            splitCount == 1 && (
              <Container sx={{my: 3}}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item md={3}></Grid>
                <Grid item xs={4} sm={4} md={7}>
                  <Box sx={{ mt: 5 }}>
                    <Typography variant='h4' sx={{fontWeight: 600}}>
                      { heading }
                    </Typography>
                    <Typography variant='h6' sx={{my: 6, color: 'blue', fontWeight: 500}} >
                      { description }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={2}>
                </Grid>
              </Grid>
            </Container>      
            )}

      </Box>
    </Box>
  )
}

export default GeneralTopCard;