import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, TextField } from '@mui/material';

export default function SelectColumnComponent(props) {
  const { options, onChange } = props;

  return (
    <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        size="small"
        sx={{ maxHeight: 15, fontSize: 8 }}
        onChange={(event, selectedColumn)=>onChange(selectedColumn)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Select to compare" />}
      />
    </FormControl>
  );
}
