import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const RedirectSlack = props => {
  const params = useParams()
  const [searchParams] = useSearchParams();
  const scope = searchParams.get('scope');

  useEffect(()=>{
    // app_mentions:read
    // im:history
    // 5576615948067.5576899212882
    // redirect_uri
    // https://slack.com/oauth/v2/authorize?scope=app_mentions:read,im:history&client_id=5576615948067.5576899212882

    console.log('----> paraxxx ', scope);
  },[]);

    return (
      <div>RedirectSlack......</div>
    )
  }
  
  export default RedirectSlack;