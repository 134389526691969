import React from 'react';
import AppBarComponent from './components/ui/AppBarComponent';
import Footer from './components/ui/Footer';
import { Box, Container, Paper, Grid } from '@mui/material';
import TermsHtml from './components/ui/Terms-Html';

// const Tf = () => (
//   <Box>
//     <Container>
//     </Container>
//   </Box>
// )

const Terms = () => {
  return (
    <div>
      <AppBarComponent />
      <Box sx={{ minHeight: 500 }}>
        <TermsHtml />
      </Box>
      <Footer />
    </div>
  )
}

export default Terms;