import React from 'react';
import FaqAccordion from './components/home/FaqAccordion';
import { Container, Box, Button } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
// import Button from '@mui/material/Button';
import AppBarComponent from './components/ui/AppBarComponent';
import Footer from './components/ui/Footer';

function Faqs() {

  // const sampleLink1 = 'https://evoked.io/result/sample-data-1';
  const sampleLink = 'https://evoked.io/result/0d41422d-fef7-4235-b45c-f7edc1ed9eda'
  const faqs = [
    { q: 'Do I need a credit card to signup', a: 'No! You can signup for free and still be able to analyze small dataset' },
    { q: 'What type of data analysis can I get', a: 'You will get summary statistics, comparative analysis, etc' },
    // { q: 'My company Slack workspace has over 200 users, how does this affect pricing', a: 'We do not worry about the number of users in your workspace.' },
    // { q: 'Can I integrate my discord community with over 6,000 users with the starter plan', a: 'Yes please! Your team members will only be able to analyze data to the limits of your plan' },
    { q: 'How do I view the result of my analyzed data', a: 'When you analyze data, you will be provided with a unique link with an access code to view your result, ' },
    { q: 'How do I use AI/ChatGPT', a: 'When viewing result, you can chat with ChatGPT to get more insights on the result' },
    // { q: 'Can I see a sample of data result', a: 'Yes please! Take a look at the result on this link' },
    { q: 'Can I see a sample of data result', a: `Yes please! Take a look at the result on the following link and use 'aaaaa' as the access code.`, link: sampleLink },
    { q: 'How do you store my data', a: 'No we do not store your data, however we store the result of your result for your to view later and you can delete your result anytime.' },
  ];


  return (
    <div className="">
      <AppBarComponent />
      <Container>
        <Container>
        <Box sx={{ my: 4 }}>
          <h3>Frequently asked questions</h3>
          <Box>
            <HelpIcon fontSize='large' />
          </Box>
          <FaqAccordion faqs={faqs} />
        </Box>
        </Container>
        </Container>
        <Footer />
    </div>
  );
}

export default Faqs;
