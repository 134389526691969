import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {  Container, Paper, Grid } from '@mui/material';
import {formatChartData2, transposeArray, formatChartData3 } from '../helpers/helper';
import ChartComponent from './chart';
import NumericTables from './result/NumericTable';
import FileSummary from './result/FileSummary';

export default function ResultFace(props) {
  const { data } = props;
  const result = data.result;
  const [columnNamesArray, setColumnNamesArray] = useState([]);
  const [columnNamesObj, setColumnNamesObj] = useState({});
  const [chartData, setChartData] = useState({});
  const [groupableStatsResult, setGroupableStatsResult] = useState({});

  const getColumnNames = () => {
    const columnNameArray = [];
    const columnNameObj = {};
    if (!result.ColumnNames || !result.ColumnNames.length) {
      return { columnNameArray, columnNameObj };
    }

    const chtD = {};
    const chType = {};
    for (let index = 0; index < result.ColumnNames.length; index++) {
      const name = result.ColumnNames[index];
      const groupable = result.GroupableStringColumnIndexs.includes(index);
      const numeric = result.NumberColumnIndexs.includes(index)
      columnNameArray.push({ name, index });
      columnNameObj[index] = {
        name,
        groupable,
        numeric,
        type: result.ColumnTypes[index],
        class: groupable ? 'groupable' : numeric ? 'numeric' : null,
        analyzable: groupable || numeric,
      };
      chType[index] = 'Bar';
      if (result.GroupableStringColumnIndexs.length && index < result.GroupableStringColumnIndexs.length) {
        const columnIndex = result.GroupableStringColumnIndexs[index];
        chtD[columnIndex] = formatChartData(result.GroupableStatsResult[columnIndex]);
      }    
    };
    setChartData({ ...chtD });

    setColumnNamesObj(columnNameObj);
    setColumnNamesArray(columnNameArray);
    setGroupableStatsResult(result.GroupableStatsResult);
    // setAnalyzableColumns([...result.GroupableStringColumnIndexs, ...result.NumberColumnIndexs]);
    return { columnNameArray, columnNameObj };
  }

  const getTitle = (index) => {
    return result.ColumnNames[result.NumberColumnIndexs[index]];
  }

  useEffect(()=>{
    getColumnNames();
  },[result]);

  const formatChartData = (summary, type = null) => {
    const dataArray = [];
    for (const key in summary) {
      const dataPoint = { value: key, count: summary[key] };
      if (type) {
        dataPoint.type = type;
      }
      dataArray.push(dataPoint);
    }

    return dataArray;
  };

  const getCompareColumns = (columnIndex) => {
    return [{name: 'Select to compare', value: null}]
      .concat(columnNamesArray
      .filter((c, index) => columnIndex !== index &&
      columnNamesObj[index].analyzable
    ));
  }

  const compareToJuniorColumn = (columnIndex, compareIndex, compareState) => {
    const columnstats = result.GroupableStatsResult[columnIndex];
    let groupableStats = result.ColumnValueByOtherColumns[compareIndex];
    let loopIndex = columnIndex;

    if (compareState === 2) {
      groupableStats = result.GroupableByNumericAnalysis[columnIndex];
      loopIndex = compareIndex;
    }

    let groupResult;
    let dataArray = []; let rowTitle = []; let tableData = []; let tableValues = [];
    let headerObj = {}; let rowTitleObj = {}; const aiTable = [];
    const tableHeaders = Object.keys(groupableStatsResult[columnIndex]);

    for(const group in groupableStats) {
      groupResult = groupableStats[group][loopIndex];
 
      const { formatted, counts, sum } = formatChartData3(groupResult, group, columnstats);
      const td = { ...groupResult, category: group, count: sum };
      const columnName = columnNamesObj[compareIndex].name;
      aiTable.push({ ...groupResult, [columnName]: group, total: sum });

      tableData.push(td);
      tableValues.push(counts);
      dataArray = [...dataArray, ...formatted];
    }

    if (compareState === 1) {
      headerObj = Object.values(groupableStatsResult[columnIndex]);
      rowTitleObj = Object.values(groupableStatsResult[compareIndex]);
      rowTitle =  Object.keys(groupableStatsResult[compareIndex]);
    }

    const table = {
      compareWith: compareIndex,
      headerObj,
      rowTitleObj,
      tableHeaders,
      rowTitle,
      tableValues,
    }

    return { table, dataArray, tableData, aiTable };
  }
  
  const compareToSeniorColumn = (columnIndex, compareIndex, compareState) => {
    const columnstats = result.GroupableStatsResult[compareIndex];

    let dataArray = []; let rowTitle = []; let tableData = []; let tableValues = [];
    const aiTable = [];
    let headerObj = {}; let rowTitleObj = {};
    const tableHeaders = Object.keys(groupableStatsResult[columnIndex]);

    let groupableStats = result.ColumnValueByOtherColumns[columnIndex];
    if (compareState === 2) {
      groupableStats = result.GroupableByNumericAnalysis[columnIndex];
    }    

    for (const group in groupableStats) {
      const groupResult = groupableStats[group][compareIndex];
      const { formatted, counts, sum } = formatChartData2(groupResult, group, columnstats);
      tableData.push({ ...groupResult, category: group, count: sum });
      const columnName = columnNamesObj[columnIndex].name;
      aiTable.push({ ...groupResult, [columnName]: group, total: sum });
      dataArray = [...dataArray, ...formatted];
      tableValues.push(counts);
    }

    if (compareState === 1) {
      headerObj = Object.values(groupableStatsResult[columnIndex]);
      rowTitleObj = Object.values(groupableStatsResult[compareIndex]);
      rowTitle = Object.keys(groupableStatsResult[compareIndex]);
    }

    const table = {
      compareWith: compareIndex,
      headerObj,
      rowTitleObj,
      tableHeaders,
      rowTitle,
      tableValues: transposeArray(tableValues),
    }

    return { table, dataArray, tableData, aiTable };
  }

  // const getCompareData = (columnIndex, compareIndex, compareState) => {
  //   let columnStatsIndex = columnIndex;
  //   let groupableIndex = columnIndex;
  //   let loopIndex = compareIndex;
  //   let groupableStats;
  //   let mainData = result.ColumnValueByOtherColumns;
  //   // let groupableStats = result.ColumnValueByOtherColumns[columnIndex];
  //   // let groupableIndex = compareIndex;
  //   let formatFn = formatChartData2;
    
  //   if (columnIndex > compareIndex) {
  //     groupableIndex = compareIndex;
  //     loopIndex = columnIndex;
  //     formatFn = formatChartData3;
  //     groupableStats = result.ColumnValueByOtherColumns[compareIndex];
  //   }

  //   if (compareState === 2) {
  //     groupableIndex = compareIndex;
  //     mainData = result.GroupableByNumericAnalysis;
  //     groupableStats = result.GroupableByNumericAnalysis[columnIndex];
  //   }

  //   let groupResult;
  //   let dataArray = []; let rowTitle = []; let tableData = []; let tableValues = [];
  //   let headerObj = {}; let rowTitleObj = {};
  //   const tableHeaders = Object.keys(groupableStatsResult[columnIndex]);

  //   const columnstats = result.GroupableStatsResult[columnStatsIndex];
  //   groupableStats = mainData[groupableIndex];


  //   for(const group in groupableStats) {
  //     groupResult = groupableStats[group][loopIndex];

  //     const { formatted, counts, sum } = formatFn(groupResult, group, columnstats);
  //     // const td = { ...groupResult, category: group, count: groupableStatsResult[columnIndex][group]};
  //     const td = { ...groupResult, category: group, count: sum };

  //     tableData.push(td);
  //     tableValues.push(counts);
  //     dataArray = [...dataArray, ...formatted];
  //   }

  //   if (compareState === 1) {
  //     headerObj = Object.values(groupableStatsResult[columnIndex]);
  //     rowTitleObj = Object.values(groupableStatsResult[compareIndex]);
  //     rowTitle =  Object.keys(groupableStatsResult[compareIndex]);
  //   }

  //   const table = {
  //     compareWith: compareIndex,
  //     headerObj,
  //     rowTitleObj,
  //     tableHeaders,
  //     rowTitle,
  //     tableValues,
  //   }

  //   return { table, dataArray, tableData };
  // }

  return (
    <div>
    <Box sx={{ minWidth: 275 }} className="App">
    </Box>

    <Container style={{ marginBottom: '25px' }}>
      <FileSummary data={data} />
    </Container>

    <Container style={{ marginTop: '25px' }}>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {result && result.DataFrameSummary && result.DataFrameSummary.ColumnSummaries.map((columnSummary, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Paper elevation={7}  key={index}>
          <div style={{ fontSize: 20, fontWeight: 700 }}></div>
            <NumericTables title={getTitle(index)} summary={columnSummary}/>
          </Paper>
        </Grid>
      ))}
    </Grid>
    </Container>

    <Container style={{ marginTop: '35px' }}>
      {
        result && result.DataFrameSummary && result.GroupableStringColumnIndexs.map((ColumnIndex, index) => (
          <ChartComponent
            key={index}
            data={chartData[ColumnIndex]}
            groupableColumnResult={groupableStatsResult}
            groupChartData={chartData}
            index={index}
            columnIndex={ColumnIndex}
            columnNamesArray={columnNamesArray}
            columnNamesObj={columnNamesObj}
            groupData={result.ColumnValueByOtherColumns[ColumnIndex]}
            // options={getCompareColumns(ColumnIndex)}
            getCompareColumns={getCompareColumns}
            compareToJuniorColumn={compareToJuniorColumn}
            compareToSeniorColumn={compareToSeniorColumn}
            groupByNumeric={result.GroupableByNumericAnalysis[ColumnIndex]}
          />
        ))
      }
    </Container>

    {/* <Container>
    {columnNamesObj[0] && <SingleResultUI
      getCompareColumns={getCompareColumns}
      compareToJuniorColumn={compareToJuniorColumn}
      compareToSeniorColumn={compareToSeniorColumn}
      getCompareData={getCompareData}
      chartData={chartData}
      groupableColumnResult={groupableStatsResult}
      columnNamesArray={columnNamesArray}
      columnNamesObj={columnNamesObj}
      groupData={result.ColumnValueByOtherColumns}
      groupableByNumericAnalysis={result.GroupableByNumericAnalysis}
    />}
    </Container> */}
    </div>
  );
}
