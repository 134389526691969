import * as React from 'react';
// import Box from '@mui/material/Box';
import { Card, CardContent, Button, Typography, Container, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
// import svg2 from '../media/storyset/svgs/topImage2.svg';
// import topImage_02 from '../media/storyset/svgs/topImage3.svg';
import topImage from '../media/storyset/svgs/design-stats-animate.svg';
// import topImage3 from '../media/storyset/svgs/analytivs-animate.svg';
import Background from '../media/bgs/wave-haikei.svg';
// import ChartVideo from '../media/animation/chart_video.mov';
// import ChartGif from '../media/animation/chart_gif3.gif';
// import slackIcon from '../media/flats/icons/slack.svg';
import discordIcon from '../media/flats/icons/discord.svg';
// import googleSheet from '../media/iconsduck/google-sheet.jpg'
// import csvIcon from '../media/iconsduck/csv.svg'
import msExcel from '../media/iconsduck/ms_excel.svg';
import slack2 from '../media/iconsduck/slack.svg';
// import discord2 from '../media/iconsduck/discord.svg';


const handleCloseUserMenu = (link) => {
  window.location.href = link;
  // setAnchorElUser(null);
};

const slackUrl = 'https://slack.com/oauth/v2/authorize?client_id=5576615948067.5576899212882&scope=app_mentions:read,im:history,links:read,chat:write,files:read,files:write,im:read,mpim:history,channels:join,channels:history,groups:history,chat:write.customize&user_scope=';
const discordUrl = 'https://discord.com/oauth2/authorize?client_id=1121772755659014298';

const card = (
  <React.Fragment>
    <CardContent sx={{px: 4, minHeight: 580, backgroundColor: '', border: 'none', width: '100%' }}>
      <Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={12} md={1}></Grid>
        <Grid item xs={4} md={5}>
          <Box sx={{height: 30}}>&nbsp;</Box>
          <Box sx={{mt: 2, pr: 3}}>
            <Typography sx={{ fontWeight: 900, mt: 3, color: '#000' }} variant='h3' color="text.secondary" gutterBottom>
              Analyze data for <br />scientific & business<br />intelligence
            </Typography>
            <Typography sx={{ mb: 1.5, mt: 4, fontSize: 17, color: '#000' }} color="text.secondary">
              Evoke meaningful insights into complex data <br /> with no technical expertise.
            </Typography>
            <Box>
            {/* <img src={slackIcon} height={50} width={50} style={{marginRight: 15}} /> */}
            <a href={slackUrl} target='_blank'><img src={slack2} height={50} width={50} style={{marginRight: 15}} /></a>
            <a href={discordUrl} target='_blank'><img src={discordIcon} height={50} width={50} style={{marginRight: 15}}  /></a>
            {/* <img src={discord2} height={50} width={50} style={{marginRight: 15}} /> */}
            <a href='https://www.microsoft.com/en-us/microsoft-365/excel' target='_blank'><img src={msExcel} height={50} width={50} style={{marginRight: 15}} /></a>
            </Box>
            <Box sx={{mt: 4}}>
              <Button size="medium" color="secondary" variant="contained" href='https://app.evoked.io'>Get started for free!</Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} md={6}>
          {/* <img src={svg2} alt="Analyze data for business intelligence" /> */}
          <Box sx={{ mt: 4}}>
            <img src={topImage} alt="Analyze data for business intelligence" width={500} height={500} />
            {/* <img src={ChartGif} alt="Chart gif"  width={'90%'} /> */}
            {/* <video width="600" height="600" src={ChartVideo}></video> */}
            {/* <img src={Background} alt="Analyze" width={300} height={300} /> */}
          </Box>
        </Grid>
        {/* <Grid item xs={4} md={1}> </Grid> */}
      </Grid>
    </CardContent>
  </React.Fragment>
);

export default function TopCard() {
  return (
      <Card 
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
          {card}
      </Card>
  );
}
