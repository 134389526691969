const rotateChart = (chartDirection) => {
  if (!chartDirection) {
    return 'transpose';
  }
  if (chartDirection === 'transpose') {
    return 'reflectX';
  }
  if (chartDirection === 'reflectX') {
    return 'reflectY';
  }

  return 'transpose';
}

const formatChartData = (summary, type = null) => {
  const dataArray = [];
  for (const key in summary) {
    let dataPoint;
    if (type) {
      dataPoint = { type: key, count: summary[key], value: type };
      // dataPoint.type = type;
    } else {
      dataPoint = { value: key, count: summary[key] };
    }
    dataArray.push(dataPoint);
  }
  return dataArray;
};

const formatChartData2 = (summary, type = null, compareColumnObj) => {
  const formatted = [];
  const counts = [];
  let sum = 0
  for (const value in compareColumnObj) {
    let dataPoint;
    const count = summary[value] || 0;
    sum = sum + count;
    dataPoint = { value, count };
    if (type) {
      dataPoint.value = type;
      dataPoint.type = value;
    }
    counts.push(count);
    formatted.push(dataPoint);
  }

  return { formatted, counts, sum };
}

const sumOfObjectKeys = obj => {
  const keysArr = Object.keys(obj);
  return keysArr.reduce((a, b) => a + b, 0);
}

const formatChartData3 = (summary, type = null, compareColumnObj) => {
  const formatted = [];
  const counts = [];
  let sum = 0;
  for (const value in compareColumnObj) {
    let dataPoint;
    const count = summary[value] || 0;
    sum = sum + count;
    dataPoint = { value, count, type };
    counts.push(count);
    formatted.push(dataPoint);
  }
  return { formatted, counts, sum };
}


const formatNumericChartData = (summary, type = null) => {
  const dataArray = [];
  for (const key in summary) {
    let dataPoint;
    if (type) {
      dataPoint = { type: key, count: summary[key], value: type };
      // dataPoint.type = type;
    } else {
      dataPoint = { value: key, count: summary[key] };
    }
    dataArray.push(dataPoint);
  }
  return dataArray;
};

const dC = (value, decimalPlace = null) => {
  if (!value) return;

  if (decimalPlace === 0) return parseInt(value.toFixed(decimalPlace));

  if (!parseInt(decimalPlace) || parseInt(decimalPlace) < 0 || parseInt(decimalPlace) > 8) {
    return parseFloat(value.toFixed(8));
  }

  return parseFloat(value.toFixed(decimalPlace))
}

const transposeArray = m => m[0].map((x,i) => m.map(x => x[i]));

const getPercent = (val, total, decimalPlace = 2) => {
  return ((val/total) * 100).toFixed(decimalPlace);
}

const getTableValue = (value, total, view) => {
  let result = value;
  if (view === 'percent') {
    result = `${getPercent(value, total)}%`;
  }
  if (view === 'both') {
    result = `${value} (${getPercent(value, total)}%)`;
  }
  return result;
}


export {
  rotateChart,
  formatChartData,
  formatChartData2,
  formatChartData3,
  dC,
  formatNumericChartData,
  transposeArray,
  getPercent,
  getTableValue,
  sumOfObjectKeys,
};
