import * as React from 'react';
import {Button, Alert, Box} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, useSearchParams } from "react-router-dom";

export default function LoginDialog(props) {
  // const params = useParams()
  const { loading, error, message, setError } = props;
  const [searchParams] = useSearchParams();
  const accessType = searchParams.get('at')

  const { resultSession, setResultSession, open } = props;
  const [openy, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function handleChange (e) {
    setResultSession({...resultSession, [e.target.id]: e.target.value});
  }

  const validateForm = () => {
    setResultSession({ ...resultSession, message: '' })
    if (resultSession.accessCode.length < 4 ) {
      setError(true);
      setResultSession({ ...resultSession, message: 'Incorrect access code' })
      return
    }
    
    const payload = {
      accessCode: resultSession.accessCode,
      reference: resultSession.resultReference,
      accessType,
    }

    props.getResult(payload)
  }

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Access</DialogTitle>
        <DialogContent>
          <Box className='danger'>
            {/* {resultSession.message} */}
            { resultSession.message && <Alert severity={error ? 'error' : 'success'}>
            {resultSession.message}
          </Alert>}

          </Box>
          <DialogContentText>
            Kindly provide the password to this URL below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="accessCode"
            label="Access code"
            type="password"
            value={resultSession.accessCode}
            fullWidth
            variant="standard"
            sx={{mt: 3}}
            onChange={handleChange}
          />
          { loading && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <Button onClick={validateForm}>Proceed
          {/* <CircularProgress /> */}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}