import React, { useState } from 'react';
import handleViewport from 'react-in-viewport';
import TopCard from './components/home/TopCard';
import SecondCard from './components/home/SecondCard';
import CustomerCard from './components/home/CustomerCard';
import HowItWorks from './components/home/HowItWorks';
// import FeaturesSection from './components/home/FeaturesSection';
// import Features from './Features';
import AppBarComponent from './components/ui/AppBarComponent';
import Timeline from './components/home/Timeline';
import Footer from './components/ui/Footer';
// import FeatureList from './components/home/FeatureList';
import FeatureSection from './components/home/FeatureSection';
// import Testimonial from './components/home/Testimonial';
import AppFeatures from './components/home/AppFeatures';
// import FooterScreen from './components/home/Footer';
import FaqSection from './components/home/FaqSection';

// import AddToSlack1 from './components/media/add_to_slack_svg1.svg';
import './styles/website.css';

function Home() {
  const HowItWorksBlock = handleViewport(HowItWorks);
  const [showHowItWorks, setShowHowItWorks] = useState(false);

  const TimelineBlock = handleViewport(Timeline);
  const [showView, setShowView] = useState(false);

  // const resultApi = process.env.REACT_APP_RESULT_API_ENDPOINT;

  return (
    <div className="">
      <AppBarComponent />
      <TopCard />
      {/* <FeatureSection /> */}
      <CustomerCard className="fnt" />
      <AppFeatures />
      {/* <FeaturesSection /> */}
      {/* <SecondCard /> */}
      {/* <FeatureList /> */}
      {/* <Features /> */}
      {/* <Timeline showHowItWorks={showHowItWorks} onEnterViewport={()=>setShowHowItWorks(true)} /> */}
      {/* <TimelineBlock showView={showView} onEnterViewport={()=>setShowView(true)} /> */}
      {/* <Testimonial /> */}
      <HowItWorks />
      <FaqSection />
      {/* <HowItWorksBlock showHowItWorks={showHowItWorks} onEnterViewport={()=>setShowHowItWorks(true)} /> */}
      {/* <Button variant="contained">Press me!</Button> */}
      <Footer />

      {/* <FooterScreen /> */}
    </div>
  );
}

export default Home;
